import {
	Component,
	OnInit,
	TemplateRef,
	OnDestroy,
	ChangeDetectorRef,
	ViewChild,
	HostListener,
	ElementRef, Input, Output, EventEmitter,
	AfterViewInit
} from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FileUploader } from 'ng2-file-upload';
import { FlocksyValidator } from '@common/validation/flocksy-validator';
import { LocalStorageService } from '@services/localstorage.service';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { UserService } from '@services/user/user.service';
import { ClientSubClientService } from '@services/client/client-subclient.service';
import { ToasterService } from 'angular2-toaster';
import { ProjectTypeService } from '@services/project/project-type.service';
import { ProjectService } from '@services/project/project.service';
import { Observable, ReplaySubject, forkJoin, of, throwError, Subject } from 'rxjs';
import { environment } from '@env/environment';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { ProjectDetailService } from '@services/project/project-detail.service';
import { TeamService } from '@services/team/team.service';
import { ProjectBucketService } from '@services/project/project-bucket.service';
import { ProjectBidService } from '@services/project/project-bid.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { DocumentService } from '@services/document/document.service';
import { S3DocumentService } from '@services/document/s3-document.service';
import * as moment from 'moment';
import { ProjectInviteDesignersService } from '@services/project/project-invite-designers.service';
import { ProjectExcludeDesignersService } from '@services/project/project-exclude-designers.service';
import { ProjectUserService } from '@services/project/project-user.service';
import { ProjectFinalizeService } from '@services/project/project-finalize.service';
import { catchError, map, takeUntil, debounceTime } from 'rxjs/operators';
import { UserClientService } from '@services/user/user-client.service';
import { ProjectAssignedUserService } from '@services/project/project-assigned-user.service';
import { BucketService } from '@services/bucket/bucket.service';
import { CanComponentDeactivate } from '../../../../guards/deactivate.guard';
import { ProjectTitle } from '../utils/interface';
import { CATEGORIES } from '@common/Categories';
import { CATEGORIES_IDS } from '@common/CategoriesIds.enum';
import { TemplatePayload } from '../../../../models/template-payload.model';
import { FileService } from '@services/file/file.service';
import { WhiteLabelService } from '@services/white-label/white-label.service';

@Component({
	selector: 'app-projects-editview',
	templateUrl: './projects-editview.component.html',
	styleUrls: ['./projects-editview.component.scss'],
})
export class ProjectsEditviewComponent
	implements OnInit, OnDestroy, CanComponentDeactivate, AfterViewInit
{
	@ViewChild('scrollToInvalidQueField') scrollToInvalidQueField!: ElementRef;
	@ViewChild('scrollToCategoryMessage') scrollToCategoryMessage!: ElementRef;
	@ViewChild('scrollToProgressBar') scrollToProgressBar!: ElementRef;
	@ViewChild('packageDetailedInfo') packageDetailedInfoTemp: TemplateRef<any>;
	@ViewChild('upgradePlanModal') upgradePlanModal: any;
	@ViewChild('confirmModal') confirmModal: any;
	@ViewChild('myInput') inputEl: ElementRef;
	@ViewChild('fileUploadInput') fileUploadInput: ElementRef;
	@ViewChild('saveAsTemplateModal') saveAsTemplateModal: TemplateRef<any>;
	@ViewChild('updateTemplateModal') updateTemplateModal: TemplateRef<any>;
	@Input('isOverlay') isOverlay: boolean = false;
	@Input('saveDraft') saveDraft$: Observable<boolean>;
	@Output('closeOverlay') closeOverlay: EventEmitter<boolean> =
		new EventEmitter();
	@Output('isEdited') isEdited: EventEmitter<boolean> = new EventEmitter();
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	isSavedProject: any;
	isMarketplaceValidationShown: boolean;
	customSizeMessage: boolean;
	isSaveProject: boolean;
	fileLoaderShown: boolean;
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.updateItemsPerSlide(event.target.innerWidth);
	}
	isDemoPlan = this._localStorageService.isDemoPlan();
	isOperationalClientUser =
		this._localStorageService.isOperationalClientUser();
	isProjectManagerUser = this._localStorageService.isProjectManagerUser();
	isAdminUser = this._localStorageService.isAdminUser();
	isClientUser = this._localStorageService.isClientUser();
	isSubClientUser = this._localStorageService.isSubClientUser();
	projectConcepts = FlocksyUtil.projectConcepts();
	projectStatus = FlocksyUtil.projectStatus();
	creativeLevels = FlocksyUtil.creativeLevels();
	MAX_ANALYTICS_ATTEMPT = FlocksyUtil.getMaxFileAnalyticsAttempt();
	FILE_ANALYTICS_INTERVAL = FlocksyUtil.getFileAnalyticsInterval();
	packageBGImage = FlocksyUtil.packageBGImage;
	deleteIcon = FlocksyUtil.delete;
	draftParam = FlocksyUtil.draftParam;
	notesIcon = FlocksyUtil.notesIcon;
	statsIcon = FlocksyUtil.statsIcon;
	includeIcon = FlocksyUtil.includeIcon;
	excludeIcon = FlocksyUtil.excludeIcon;
	selectedExcludeIcon = FlocksyUtil.selectedExcludeIcon;
	selectedIncludeIcon = FlocksyUtil.selectedIncludeIcon;
	discountCouponBgImage = FlocksyUtil.discountImage;
	satisfactionGuaranteedImg = FlocksyUtil.satisfactionGuaranteedImg;
	satisfactionGuaranteedTooltip = FlocksyUtil.satisfactionGuaranteedTooltip;
	createProjectPackageListLimit = FlocksyUtil.createProjectPackageListLimit;
	uploadFileIcon = FlocksyUtil.uploadFileIcon;
	addSubUserImg = FlocksyUtil.addSubUserImg;
	defaultFileImg = FlocksyUtil.defaultFileImg;
	imageMimeTypes = FlocksyUtil.getImageMimeTypes();
	userId = this._localStorageService.getUserId();
	config = this._localStorageService.getEditorConfig();
	modalDesignerProjectRef: BsModalRef;
	modalHireDesignerRef: BsModalRef;
	confirmModalRef: BsModalRef;
	modalNotesRef: BsModalRef;
	modalStatsRef: BsModalRef;
	upgradePlanModalRef: BsModalRef;
	authSeamLessData: any;
	currentUser: any;
	action: any;
	aiDetails: any;
	progressBarPercentage: any;
	progressBarPercentageSection2: any;
	isEligibleForHire: any;
	activeModalType;
	packageInfo;
	packageId: any;
	oProjectsData: any = {};
	projectTypeId: number;
	projectTitleId: number;
	draftParams: number;
	userType: string;
	projectTitle: string;
	selectedProjectType: string;
	valForm: FormGroup;
	hasMarketplaceAccess: boolean;
	isUserOnPausePlan: boolean;
	isPaymentModalOpen: boolean;
	isUpdatedBilling: boolean;
	openPaymentModel: boolean;
	isPaymentSucceeded: boolean;
	showWhichStatus = false;
	bCopyBrief = false;
	bDisableType = false;
	customSizeEnabled = false;
	bDisableTitle = false;
	isEligibleActive = false;
	bShowProgressBar = false;
	isMarketplaceTab = false;
	bDisableBtn = false;
	isValidationShown = false;
	marketPlaceCategoryTypes: any = false;
	validateProjectTitle = true;
	apiFileUploading = false;
	isSkeletonLoading = false;
	isCarouselSkeletonLoading = true;
	oProject;
	pendingProjectsCount: number;
	pendingProjectsCountArr;
	sort_order: any;
	projectId: any;
	selectedPackage;
	teamInviteStatus;
	marketPlaceCategories: any;
	statusForAdmin = 'all';
	oDefs: any = {};
	oQueryString: object = {};
	oProjectQuestion: object = {};
	oCopyBriefFiles = [];
	oQuestionTypes: any = {};
	oBaseDropZoneOver = {};
	oFileAnalyticsId = {};
	oFileProgress = {};
	oDesignerUser: object = {};
	oPostCopyDocs: object = {};
	oFileAnalyticsInterval = {};
	oUploader = {};
	directionDetail: any;
	oSelectedType: any;
	oSelectedTitle: any;
	skeletonDetail = {};
	category: any = {
		type: '',
		title: '',
		searchableText: '',
		projectTitle: '',
		customSizes: [],
		isSearchFieldDisabled: false,
		aiImgList: [],
		aiTextList: [],
		assignedUser: [],
		directionList: [],
		selectedSubUser: [],
		uploadedFile: [],
		selectedBucket: [],
		questionForms: {
			activeStep: 1,
			totalStep: 0,
			disabledStep: {
				1: false,
			},
		},
	};
	marketplaceCategory: any = {
		type: null,
		title: '',
		selectedTitle: '',
		projectTitle: '',
		searchableTextId: '',
		isSearchFieldDisabled: false,
		directionList: [],
		uploadedFile: [],
		selectedBucket: [],
		questionForms: {
			activeStep: 1,
			totalStep: 0,
			disabledStep: {
				1: false,
			},
		},
	};
	loadMorePagination: any = {
		currentPage: 1,
	};
	oSuggestedTmPagination: any = {
		totalRecords: 0,
		currentPage: 1,
		itemsPerPage: 0,
	};
	oTmPagination: any = {
		totalRecords: 0,
		currentPage: 1,
		itemsPerPage: 0,
	};
	currentProject: any;
	marketplacePackages = [];
	marketplaceQuestions = [];
	aTeamMembers = [];
	aAppListString = [];
	designersWorked = [];
	aExcludedTeamMembers = [];
	aPayloadInviteDesigner = [];
	aDesignerProjects = [];
	startedProject = [];
	aProjectUsers = [];
	aiImageList = [];
	aFeedbackQuestions = [];
	aSuggestedTeamMembers = [];
	platinumInvitedDesigners = [];
	subClientUserList = [];
	isPackageNotFound: boolean;
	lastLoadedPackages: any;
	public interval = 0;
	apiFileUploadCount = 0;
	itemsPerSlide = 5;
	isSkeletonShown = true;
	@ViewChild('scrollToInvalidField') scrollToInvalidField!: ElementRef;
	customTypeList = [
		{ id: 'px', name: 'px' },
		{ id: 'in', name: 'in' },
		{ id: 'ft', name: 'ft' },
	];
	selectedProjectTitle:ProjectTitle | undefined;
	currentProjectSlide: number = 1;
	currentProjectSlideRange: number[];

	CATEGORIES = CATEGORIES;
	legacyBrief = false;
	customSizesLength = 0;
	customSizesArray: Array<{
		customWidth: number;
		customHeight: number;
		customType: string;
		selected: boolean;
	}> = [];
	newCustomSize: {
		customWidth: number;
		customHeight: number;
		customType: string;
	} = {
		customWidth: null,
		customHeight: null,
		customType: '',
	};
	editingIndex: number = -1;
	assignedUserDataLoaded = false;
	projectIdFromParams;

	currentTemplateId: number;
	isAgreed: boolean = false;
	filterCategoriesForm = new FormGroup({
		seeAll: new FormControl(true),
		graphicDesign: new FormControl(false),
		copywriting: new FormControl(false),
		motionGraphics: new FormControl(false),
		videoEditing: new FormControl(false),
		websiteDevelopment: new FormControl(false),
		customIllustrations: new FormControl(false),
		voiceOvers: new FormControl(false),
		virtualAssistants: new FormControl(false),
		templates: new FormControl(true),
	});
	categoryMapping = {
		seeAll: 'seeAll',
		graphicDesign: CATEGORIES_IDS.GRAPHIC_DESIGN,
		copywriting: CATEGORIES_IDS.COPYWRITING,
		motionGraphics: CATEGORIES_IDS.MOTION_GRAPHICS,
		videoEditing: CATEGORIES_IDS.VIDEO_EDITING,
		websiteDevelopment: CATEGORIES_IDS.WEBSITE_DEVELOPMENT,
		customIllustrations: CATEGORIES_IDS.CUSTOM_ILLUSTRATIONS,
		voiceOvers: CATEGORIES_IDS.VOICE_OVERS,
		virtualAssistants: CATEGORIES_IDS.VIRTUAL_ASSISTANTS,
		templates: 'TEMPLATES',
	};
	templateName = new FormControl(null, [Validators.required]);
	private previousCategoryFilters = this.filterCategoriesForm.value;

	sortingOptions = [
		{
			label: 'Most Recent',
			value: 'most-recent',
		},
		// {
		// 	label: 'Recommended',
		// 	value: 'recommended',
		// },
		{
			label: 'Most Popular',
			value: 'popular',
		},
		{
			label: 'A-Z',
			value: 'title',
		},
		{
			label: 'Z-A',
			value: '-title',
		},
	];
	actionItemsAll = [
		{
			label: 'Start Project',
			value: 'start',
		},
		{
			label: 'Save as Draft',
			value: 'saveDraft',
		},
		{
			label: 'Save as Template',
			value: 'saveTemplate',
		},
		{
			label: 'Save as New Template',
			value: 'saveNewTemplate',
		},
		{
			label: 'Update Template',
			value: 'updateTemplate',
		},
	];
	actionItems = [];
	selectedAction = { value: 'start', label: 'Start Project' };
	selectedItem = new FormControl('start');
	isDropdownOpen = false;
	selectedSort = this.sortingOptions[0].value;
	isAnyCategoryCheckboxSelected = true;
	isCategoriesFilterOpen = true;
	isTemplatesPresentInCategory = false;
	filterValues$;
	private titlesSubject = new Subject<any>();

	modalSaveAsTemplateModal: BsModalRef;
	modalUpdateTemplateModal: BsModalRef;
	closeIcon = FlocksyUtil.closeIcon;
	isWhiteLabelledUser = this._whiteLabelService.isWhiteLabelledUser();

	constructor(
		private _formBuilder: FormBuilder,
		private _localStorageService: LocalStorageService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private changeDetectorRef: ChangeDetectorRef,
		private _dashboardService: DashboardService,
		private _userService: UserService,
		private _clientSubClientService: ClientSubClientService,
		private _toasterService: ToasterService,
		private _projectInviteDesignersService: ProjectInviteDesignersService,
		private _projectExcludeDesignersService: ProjectExcludeDesignersService,
		private _projectTypeService: ProjectTypeService,
		private _projectService: ProjectService,
		private _projectDetailService: ProjectDetailService,
		private _teamService: TeamService,
		private _projectBucketService: ProjectBucketService,
		private _projectBidService: ProjectBidService,
		private _projectFinalizeService: ProjectFinalizeService,
		private _userClientService: UserClientService,
		public _analyticsService: AnalyticsService,
		private _bsModalService: BsModalService,
		private _documentService: DocumentService,
		private _projectUserService: ProjectUserService,
		private _projectAssignedUserService: ProjectAssignedUserService,
		private _s3DocumentService: S3DocumentService,
		private _bucketService: BucketService,
		private _fileService: FileService,
		private _whiteLabelService: WhiteLabelService,
	) {
		this.authSeamLessData = this._localStorageService.getUserAuthSeamLess();
		this.isUserOnPausePlan = this._localStorageService.isUserOnPausePlan();
		this.isUpdatedBilling = this._localStorageService.isUpdatedBilling();
		this.oQuestionTypes =
			this._localStorageService.getProjectQuestionType();
		this.userType = this._localStorageService.getUserType();
		this.currentUser = this._localStorageService.getCurrentUser();
		this.hasMarketplaceAccess =
			this._localStorageService.hasMarketplaceAccess();
		if (
			this.isAdminUser ||
			this._localStorageService.isProjectManagerUser()
		) {
			this.showWhichStatus = true;
		}
		this._activatedRoute.queryParams.subscribe((params) => {
			this.projectId = params['project_id'];
			this.projectIdFromParams = params['project_id'];
			this.action = params.action;
			this.packageId = params.package_id;
			this.projectTypeId = params.project_type_id;
			this.projectTitleId = params.project_title_id;
			this.projectTitle = params.project_title;
			if (this.action == 3) {
				this.bCopyBrief = true;
			}
			if (this.isProjectManagerUser && this.action == 2) {
				this.bCopyBrief = true;
			}
		});
		this.updateItemsPerSlide(window.innerWidth);
		this.titlesSubject
		.pipe(debounceTime(300)) 
		.subscribe((titles) => {
			this.performUpdateTitles(titles);
		});
	}

	ngOnInit() {
		this._userService
			.getAvailableProjectTypes()
			.subscribe((projectTypes) => {
				this.disableNotAllowedCategories(projectTypes);
			});
		if (this.saveDraft$) {
			this.saveDraft$
				.pipe(takeUntil(this.destroyed$))
				.subscribe((save: boolean) => {
					if (save) {
						this.saveAsDraft(null);
					}
				});
		}

		if (
			this.hasMarketplaceAccess &&
			!this.isSubClientUser &&
			!this.isMarketplaceTab &&
			!(
				this.authSeamLessData?.plan_activated &&
				this.isUpdatedBilling &&
				!this.isUserOnPausePlan
			)
		) {
			this.isMarketplaceTab = true;
		}
		if (
			!this.isAdminUser &&
			!this._localStorageService.isProjectManagerUser()
		) {
			this.getPendingCount();
			this.getEligibleActiveProject(
				this._localStorageService.getUserId()
			);
		}
		if (
			this.isClientUser ||
			this.isAdminUser ||
			this.isProjectManagerUser
		) {
			this.retrieveProjectTitle();
		} else if (this.isSubClientUser) {
			this.retrievePermissions(
				this._localStorageService.getParentId(),
				this._localStorageService.getUserId()
			);
		}
		this.oUploader['step1'] = new FileUploader({ url: 'your-upload-url' });
		this.actionItems = this.polyfilledStructuredClone(this.actionItemsAll);
	}

	ngAfterViewInit() {
		this.filterValues$ = this.createSubscriptionFilterChanges();
		this.updateDropdown();
	}

	private applyAnySelectedCategoryIcon(filterValues: any): boolean {
		if (
			filterValues['seeAll'] === true &&
			filterValues['templates'] !== true
		) {
			return false;
		}

		let isAnyChecked = false;
		for (const key in filterValues) {
			if (filterValues[key] === true) {
				isAnyChecked = true;
				break;
			}
		}
		return isAnyChecked;
	}

	disableNotAllowedCategories(projectTypes) {
		// Loop through the form controls (from categoryMapping) and check against the response
		Object.keys(this.categoryMapping).forEach((formControlName) => {
			const categoryId = this.categoryMapping[formControlName];
			// Find the corresponding data object from the response
			const categoryData = projectTypes.data.find(
				(item) => item.project_type_id === categoryId
			);
			if (categoryData) {
				const formControl =
					this.filterCategoriesForm.get(formControlName);
				if (formControl) {
					if (!categoryData.is_available) {
						formControl.disable();
					} else {
						formControl.enable();
					}
				}
			}
		});
	}

	projectSlideChange(event: number[]) {
		this.currentProjectSlideRange = [...event];
	}

	addCustomSize() {
		if (
			this.newCustomSize.customWidth &&
			this.newCustomSize.customHeight &&
			this.newCustomSize.customType
		) {
			if (this.editingIndex > -1) {
				this.customSizesArray[this.editingIndex] = {
					customWidth: this.newCustomSize.customWidth,
					customHeight: this.newCustomSize.customHeight,
					customType: this.newCustomSize.customType,
					selected: true,
				};
				this.editingIndex = -1;
			} else {
				this.customSizesArray.push({
					customWidth: this.newCustomSize.customWidth,
					customHeight: this.newCustomSize.customHeight,
					customType: this.newCustomSize.customType,
					selected: true,
				});
			}
			this.newCustomSize = {
				customWidth: null,
				customHeight: null,
				customType: '',
			};
			this.customSizeMessage = false;
			this.checkIfCustomSizeEnabled();
		} else {
			this.customSizeMessage = true;
		}
	}

	editCustomSize(index: number) {
		this.newCustomSize = { ...this.customSizesArray[index] };
		this.editingIndex = index;
	}

	deleteCustomSize(size) {
		this.customSizesArray = this.customSizesArray.filter((s) => s !== size);
		this.checkIfCustomSizeEnabled();
	}

	checkIfCustomSizeEnabled() {
		if (this.customSizesArray.length > 0) {
			this.customSizeEnabled = true;
		} else {
			this.customSizeEnabled = false;
		}
	}

	onCheckboxChange(size, event) {
		size.selected = event.target.checked;
	}

	openModal(template: TemplateRef<any>): void {
		this.upgradePlanModalRef = this._bsModalService.show(
			template,
			Object.assign({
				keyboard: false,
				backdrop: 'static',
				modalClass: 'modal-dialog',
				class: 'modal-lg modal-full update-plan-modal-wrapper',
			})
		);
	}

	setSelectedProject(project:ProjectTitle, index: number) {
		if(this.selectedProjectTitle && this.selectedProjectTitle.project_title_id === project.project_title_id) return;
		if(this.itemsPerSlide >= FlocksyUtil.screenSizes.MEDIUM) {
			//to get current slide number
			this.currentProjectSlide = (this.currentProjectSlideRange[this.itemsPerSlide - 1]+1)/this.itemsPerSlide;
			if(!this.selectedProjectTitle) this.itemsPerSlide--;
			let oDefsCopy = [...this.oDefs["titles"]];
			this.oDefs["titles"] = [];
			setTimeout(() => {
				this.oDefs["titles"] = [...oDefsCopy];
				setTimeout(() => {
					//to move towards current slide
					if(document){
						let nextButton: HTMLAnchorElement = document.querySelector(".right.carousel-control.carousel-control-next");
						while(this.currentProjectSlide > 1) {
							nextButton.click();
							this.currentProjectSlide--;
						}
					}

				},100)

			},500)
			this.selectedProjectTitle = project;

		}
	}

	switchTab(activeTab) {
		if(this.selectedProjectTitle) {
			this.selectedProjectTitle = undefined;
			this.itemsPerSlide++;
		}

		if (activeTab) {
			this.isMarketplaceTab = activeTab;
			this.category['titlesList'] = this.oDefs['titles'];
			this._bucketService.selectedBucket.next(
				this.marketplaceCategory['selectedBucket']
			);
			this.oDefs['titles'] = [];
			setTimeout(() => {
				this.oDefs['titles'] =
					this.marketplaceCategory['titlesList'] ||
					this.oDefs.marketplaceTitles;
			});
		} else {
			this.isMarketplaceTab = activeTab;
			this._bucketService.selectedBucket.next(
				this.category['selectedBucket']
			);
			this.marketplaceCategory['titlesList'] = this.oDefs['titles'];
			this.oDefs['titles'] = [];
			setTimeout(() => {
				this.oDefs['titles'] =
					this.category['titlesList'] ||
					this.oDefs?.subscriptionTitles;
			});
		}
	}

	getPendingCount() {
		const params = {};
		if (this.isProjectManagerUser) {
			params['user_id'] = this.oProject?.created_by_parent_id
				? this.oProject?.operational_id
				: this.oProject?.created_by;
		}
		this._dashboardService
			.getPendingProjectsCount(params)
			.subscribe((res) => {
				this.pendingProjectsCount = res.count;
				this.pendingProjectsCountArr = Array(res.count + 1)
					.fill(0)
					.map((x, i) => i);
				this.sort_order = this.pendingProjectsCountArr?.length;

				if (this.action == 'redo-project') {
					this.pendingProjectsCountArr.length = 1;
					this.sort_order = 1;
				}
			});
	}

	getEligibleActiveProject(userId) {
		this._userService.getEligibleActiveProject(userId).subscribe((res) => {
			this.isEligibleActive = res.data.is_eligible;
		});
	}

	adminFilter(status) {
		this.statusForAdmin = status;
		this.category['type'] = '';
		this.category['title'] = '';
		this.retrieveProjectTitle();
	}

	onSortChange(sortOption) {
		this.selectedSort = sortOption[0].data.value;
		this.retrieveProjectTitle();
	}

	performDropdownAction(action) {
		switch (action.value) {
			case 'start':
				if (this.checkProjectValid()) {
					this.submitProjectInfo();
				}
				break;

			case 'saveDraft':
				if (this.checkProjectValid()) {
					this.saveAsDraft(null);
				}
				break;

			case 'updateTemplate':
				if (this.checkTemplateValid()) {
					this.initUpdateTemplateModal(this.updateTemplateModal);
				}
				break;

			case 'saveTemplate':
			case 'saveNewTemplate': // Combined cases since they perform the same action
				if (this.checkTemplateValid()) {
					this.initSaveTemplateModal(this.saveAsTemplateModal);
				}
				break;
		}
	}
	checkProjectValid() {
		if (
			!this.category['projectTitle'].length ||
			!this.category['directionList']?.length ||
			(!this.category['questionForms']['steps']?.valid &&
				this.oDefs['questions'][this.category?.title]?.length)
		) {
			this.isValidationShown = true;
			this.bDisableBtn = false;
			this.scrollToInvalidControl();
			return false;
		}
		return true;
	}

	checkTemplateValid() {
		if (this.category['projectTitle'].length === 0) {
			this.isValidationShown = true;
			this.scrollToInvalidControl();
			this.bDisableBtn = false;
			return false;
		}
		return true;
	}

	retrieveProjectTitle() {
		let data = {};
		let sort = {};
		sort['sort'] = this.selectedSort;
		data['status'] = 1;
		if (
			(this.isAdminUser || this.isProjectManagerUser) &&
			this.statusForAdmin == 'active'
		) {
			data['status'] = 1;
		}
		if (this.bCopyBrief && this.isProjectManagerUser) {
			data['status'] = 1;
		}
		this._projectService
			.retrieveProjectTitle(this.bCopyBrief, data, sort)
			.subscribe((res) => {
				this.oDefs['titles'] = [];
				this.oDefs['allTitles'] = res?.data;
				this.oDefs['aiConfig'] = res?.config;
				this.oDefs['subscriptionTitles'] = res?.data.filter(
					(resData) => {
						return resData?.concept_id == 1;
					}
				);
				this.oDefs['marketplaceTitles'] = res?.data.filter(
					(resData) => {
						return resData?.concept_id == 2;
					}
				);
				// this.changeDetectorRef.detectChanges();
				setTimeout(() => {
					if (this.isMarketplaceTab) {
						this.oDefs['titles'] = this.oDefs['marketplaceTitles'];
					} else {
						this.oDefs['titles'] = [
							...this.oDefs['subscriptionTitles'],
						];
						this.retrieveTemplates();
					}
				});
				if (!this.currentTemplateId) {
					this.setValueViaQueryString();
				}

				this.isSkeletonLoading = true;
				this.skeletonInfo();
				if (
					this.hasMarketplaceAccess &&
					!this.currentUser['plan_id'] &&
					!this.packageId
				) {
					if (this.projectTypeId && this.projectTitle) {
						this.onMarketplaceNextTitle({
							project_type_id: this.projectTypeId,
							title: this.projectTitle,
						});
						this.oDefs.titles = this.oDefs.marketplaceTitles.filter(
							(item) =>
								item.project_title_id ==
								+this.marketplaceCategory['selectedTitleId']
						);
					}
					if (this.projectTitleId) {
						this.onSelectMarketplaceProjectTitle(
							this.projectTitleId
						);
						this.marketplaceCategory['title'] = Number(
							this.projectTitleId
						);
					}
				} else if (
					this.hasMarketplaceAccess &&
					this.currentUser['plan_id'] &&
					this.packageId
				) {
					if (this.projectTypeId && this.projectTitle) {
						this.onMarketplaceNextTitle({
							project_type_id: this.projectTypeId,
							title: this.projectTitle,
						});
						let categoryIndex =
							this.oDefs.marketplaceTitles.findIndex(
								(item) =>
									item.project_title_id ==
									+this.marketplaceCategory['selectedTitleId']
							);
						this.marketplaceCategory['titleName'] =
							this.oDefs.marketplaceTitles[categoryIndex]?.title;
						let selectedData = {
							...this.oDefs.marketplaceTitles[categoryIndex],
						};
						this.oDefs.marketplaceTitles.splice(categoryIndex, 1);
						if (Object.keys(selectedData)?.length) {
							this.oDefs.marketplaceTitles.unshift(selectedData);
						}
						this.oDefs.titles = this.oDefs.marketplaceTitles;
						this.marketplaceCategory['isSearchFieldDisabled'] =
							true;
					}
					if (this.projectTitleId) {
						this.onSelectMarketplaceProjectTitle(
							this.projectTitleId
						);
						this.marketplaceCategory['title'] = Number(
							this.projectTitleId
						);
					}
				} else if (
					this.hasMarketplaceAccess &&
					!this.currentUser['plan_id'] &&
					this.packageId
				) {
					if (this.projectTypeId && this.projectTitle) {
						this.onMarketplaceNextTitle({
							project_type_id: this.projectTypeId,
							title: this.projectTitle,
						});
					}
					if (this.projectTitleId) {
						this.onSelectMarketplaceProjectTitle(
							this.projectTitleId
						);
						this.marketplaceCategory['title'] = Number(
							this.projectTitleId
						);
					}
				} else {
					if (this.projectTypeId && this.projectTitle) {
						this.onNextTitle({
							project_type_id: this.projectTypeId,
							title: this.projectTitle,
						});
					}

					if (this.projectTitleId) {
						// this.onSelectProjectTitle(this.projectTitleId);
						this.category['title'] = Number(this.projectTitleId);
						let categoryIndex =
							this.oDefs.subscriptionTitles.findIndex(
								(item) =>
									item.project_title_id ==
									this.category['title']
							);
						this.category['titleName'] =
							this.oDefs.subscriptionTitles[categoryIndex]?.title;
						let selectedData = {
							...this.oDefs.subscriptionTitles[categoryIndex],
						};
						this.oDefs.subscriptionTitles.splice(categoryIndex, 1);
						if (Object.keys(selectedData)?.length) {
							this.oDefs.subscriptionTitles.unshift(selectedData);
						}
						let titleIndex =
							this.oDefs.subscriptionTitles.findIndex(
								(item) =>
									item.project_title_id ==
									this.category['title']
							);
						this.category['title'] =
							this.oDefs.subscriptionTitles[
								titleIndex
							]?.project_title_id;
						this.category['type'] =
							this.oDefs.subscriptionTitles[
								titleIndex
							]?.project_type_id;
						this.selectedProjectType =
							this.oDefs.subscriptionTitles[titleIndex]?.title;
						this.getSuggestedTeamMembers(true);
						this.initQuestionDefs();
						this.getTeamMembers(true);
					}
				}
			});
	}

	retrieveTemplates() {
		this._projectService.getTemplates().subscribe((res) => {
			let templates = res.map((tmp) => {
				return {
					...tmp,
					isTemplate: true,
					type: {},
				};
			});
			this.isTemplatesPresentInCategory = !!templates;
			this.checkIfTemplatesPresent();
			this.oDefs['subscriptionTitles'].unshift(...templates);
			this.oDefs.titles = [];
			this.changeDetectorRef.detectChanges();
			this.oDefs['titles'] = this.oDefs['subscriptionTitles'];
			this.setValueViaQueryString();
			this.filterByCategories();
		});
	}

	retrievePermissions(parentId, userId) {
		this._clientSubClientService
			.getPermissions(parentId, userId)
			.subscribe((data) => {
				let canManageAllProjects =
					this._clientSubClientService.canManageAllProjects(
						data?.data?.permissions
					);
				let canManageOwnProjects =
					this._clientSubClientService.canManageOwnProjects(
						data?.data?.permissions
					);
				let canViewManageOwnProjects =
					this._clientSubClientService.canViewManageOwnProjects(
						data?.data?.permissions
					);

				if (
					canManageOwnProjects ||
					canViewManageOwnProjects ||
					canManageAllProjects
				) {
					this.retrieveProjectTitle();
				} else {
					this._toasterService.pop(
						'error',
						"Sorry, you're not able to create projects."
					);
					this._router.navigate(['/projects/active']);
				}
			});
	}

	setValueViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('type' in params) {
				this.category['type'] = params['type'];
				this.setSelectedType();
				this.bDisableType = true;
			}
			if ('title' in params) {
				this.category['title'] = params['title'];
				this.setSelectedTitle();
				this.initQuestionDefs();
				this.bDisableTitle = true;
			}
			if ('action' in params) {
				this.oQueryString['parent_action'] = params['action'];
				if (
					this.oQueryString['parent_action'] == 2 ||
					this.oQueryString['parent_action'] == 3
				) {
					this.bCopyBrief = true;
				}
			}
			if ('project_id' in params) {
				this.projectId = params['project_id'];
				this.oQueryString['parent_id'] = params['project_id'];
				this.retrieveQuestionAnswers(params['project_id']);
				this.getExcludedDesigners();
			}
		});
	}

	showWhichStatusChanges() {
		if (
			(this.isAdminUser || this.isProjectManagerUser) &&
			this.showWhichStatus
		) {
			if (this.oDefs) {
				for (const key in this.oDefs['titles']) {
					for (const oTitle of this.oDefs['titles'][key]) {
						oTitle['title'] =
							oTitle['title'] +
							' - ' +
							(oTitle['status'] == '1' ? 'Active' : 'Inactive');
					}
				}
			}
		}
	}

	onMarketplaceNextTitle(type: any) {
		if (
			this.currentProject?.concept_id == this.projectConcepts?.marketplace
		)
			return;
		this.isMarketplaceTab = true;
		this.marketplaceCategory['selectedTitleId'] = type?.project_title_id
			? type?.project_title_id
			: this.projectTitleId;
		this.marketplaceCategory['type'] = +(type?.project_type_id
			? type?.project_type_id
			: this.projectTypeId);
		this.marketplacePackages = [];
		this.marketplaceQuestions = [];
		this.lastLoadedPackages = [];
		this.marketplaceCategory['titleName'] = type?.title;
		this.isPackageNotFound = false;
		this.projectTypeId = type?.project_type_id;
		this.changeDetectorRef.detectChanges();
		this._activatedRoute.queryParams.subscribe((params) => {
			this.marketplaceCategory['title'] = [0];
			this.onSelectMarketplaceProjectTitle(0);
		});
	}

	onSelectMarketplaceProjectTitle(event) {
		if (
			this.currentProject?.concept_id == this.projectConcepts?.marketplace
		)
			return;
		this.projectTitleId = event != undefined ? event : this.projectTitleId;
		this.marketplaceCategory['title'] =
			event != undefined
				? event == 0
					? [event]
					: event
				: this.projectTitle;
		this.marketplaceQuestions = [];
		this.marketplacePackages = [];
		this.isPackageNotFound = false;
		this.selectedPackage = null;
		this.loadMorePagination['currentPage'] = 1;
		this.getMarketplacePackages();
	}

	onNextTitle(type: object) {
		if (typeof type != 'object' && type !== null) {
			type = this.oDefs.types.find(
				(item) => item.project_type_id === type
			);
		}
		this.category['type'] = type['project_type_id'];
		this.category['title'] = '';
		this.marketplaceCategory['type'] = '';
		this.projectTypeId = null;
		this.marketplaceQuestions = [];
		this.selectedPackage = null;
		this.selectedProjectType = type['title'];
		if (this.isAdminUser || this.isProjectManagerUser) {
			return false;
		}
		this.aTeamMembers = [];
		this.oTmPagination['totalRecords'] = 0;
		this.aSuggestedTeamMembers = [];
		this.oSuggestedTmPagination['totalRecords'] = 0;
	}

	onSelectProjectTitle(event, index) {
		if (event?.isTemplate) {
			this.category.selected = event.id + 'tmp';
			this.category['titleName'] = event?.title;
			this.selectedProjectType = event?.type?.title;
			this.category.projectTitle = event?.project_title;
			this.category.currentIndex = index;
			this.getTemplateById(event.id);
			this.updateDropdown();
			return;
		}

		if (!isNaN(event?.project_title_id)) {
			this.currentTemplateId = null;
			this.category.selected = event?.project_title_id + 'prj';
			this.category['title'] = event?.project_title_id;
			this.category['type'] = event?.type?.project_type_id;
			this.category['titleName'] = event?.title;
			this.selectedProjectType = event?.type?.title;
			this.getSuggestedTeamMembers(true);
			this.initQuestionDefs();
			this.getTeamMembers(true);
			this.updateDropdown();
		}
	}

	updateDropdown(condition = 'std') {
		this.actionItems = [];
		setTimeout(() => {
			if (this.currentTemplateId) {
				this.actionItems = [
					{
						label: 'Start Project',
						value: 'start',
					},
					{
						label: 'Save as Draft',
						value: 'saveDraft',
					},
					{
						label: 'Save as New Template',
						value: 'saveNewTemplate',
					},
					{
						label: 'Update Template',
						value: 'updateTemplate',
					},
				];
				this.selectedItem.patchValue('start');
				return;
			}
			if (this.action == 'start-project') {
				this.actionItems = [
					{
						label: 'Start Project',
						value: 'start',
					},
					{
						label: 'Update Draft',
						value: 'saveDraft',
					},
					{
						label: 'Save as Template',
						value: 'saveTemplate',
					},
				];
				return;
			}

			if (condition === 'std') {
				this.actionItems = [
					{
						label: 'Start Project',
						value: 'start',
					},
					{
						label: 'Save as Draft',
						value: 'saveDraft',
					},
					{
						label: 'Save as Template',
						value: 'saveTemplate',
					},
				];
			}
		});
	}

	getMarketplacePackages() {
		let param;
		if (this.packageId) {
			param = {
				package_id: this.packageId,
				sort: 'package_id',
			};
		}
		param = {
			...param,
			page: this.loadMorePagination['currentPage'],
		};

		if (this.projectTitleId && this.marketplaceCategory['title'][0] !== 0) {
			param = {
				...param,
				'filter[project_title_id]': this.projectTitleId,
			};
		}
		this._projectTypeService
			.getMarketplacePackages(this.projectTypeId, param)
			.subscribe((val) => {
				this.marketplacePackages = [
					...this.marketplacePackages,
					...val?.data?.data,
				];
				this.lastLoadedPackages = val?.data;
				this.isPackageNotFound =
					this.marketplacePackages.length == 0 ? true : false;
				this.loadMorePagination['currentPage'] =
					val?.data?.current_page;
				if (this.packageId) {
					let isSelectPackage = this.marketplacePackages.find(
						(val) => val?.id == this.packageId
					);
					this.onSelectPackage(isSelectPackage);
				}
			});
	}

	onSelectPackage(selectedPackage) {
		this.modalDesignerProjectRef?.hide();
		if (
			this.currentProject?.concept_id == this.projectConcepts?.marketplace
		)
			return;
		this.selectedPackage = selectedPackage;
		this.projectTitleId = selectedPackage?.project_title_id;
		this.marketplaceCategory['questionForms'] = [];
		// this._projectTypeService
		// 	.getMarketplaceQuestions(this.projectTypeId, this.projectTitleId)
		// 	.subscribe((val) => {
		// 		this.marketplaceQuestions =
		// 			val?.data?.questions[this.projectTitleId];
		// 		this.marketplaceCategory['questionForms']['totalStep'] =
		// 			val.data.questions[this.projectTitleId]?.length;
		// 		this.marketplaceCategory['questionForms']['activeStep'] = 1;
		// 		this.marketplaceCategory['questionForms']['steps'] =
		// 			this._formBuilder.group({});
		// 		this.marketplaceCategory['questionForms']['steps'].reset();
		// 		for (
		// 			let i = 0;
		// 			i < val.data.questions[this.projectTitleId]?.length;
		// 			i++
		// 		) {
		// 			const step = i + 1;
		// 			let stepControl: FormGroup;
		// 			let field_required = false;
		// 			if (
		// 				val.data.questions[this.projectTitleId][i][
		// 					'field_required'
		// 				]
		// 			) {
		// 				field_required = true;
		// 			}
		// 			if (
		// 				val.data.questions[this.projectTitleId][i][
		// 					'project_question_type_id'
		// 				] == this.oQuestionTypes['checkbox']
		// 			) {
		// 				if (field_required) {
		// 					stepControl = this._formBuilder.group({
		// 						title: this._formBuilder.array(
		// 							this.initFormArray(
		// 								val.data.questions[this.projectTitleId][
		// 									i
		// 								]['field_options']
		// 							),
		// 							Validators.compose([
		// 								Validators.required,
		// 								FlocksyValidator.minLengthCheckbox(1),
		// 							])
		// 						),
		// 					});
		// 				} else {
		// 					stepControl = this._formBuilder.group({
		// 						title: this._formBuilder.array(
		// 							this.initFormArray(
		// 								val.data.questions[this.projectTitleId][
		// 									i
		// 								]['field_options']
		// 							)
		// 						),
		// 					});
		// 				}
		// 			} else if (
		// 				val.data.questions[this.projectTitleId][i][
		// 					'project_question_type_id'
		// 				] == this.oQuestionTypes['multienum']
		// 			) {
		// 				this.initAppListStringWithIdName(
		// 					val.data.questions[this.projectTitleId][i][
		// 						'field_options'
		// 					],
		// 					val.data.questions[this.projectTitleId][i][
		// 						'field_options'
		// 					]
		// 				);
		// 				if (field_required) {
		// 					stepControl = this._formBuilder.group({
		// 						title: [
		// 							[],
		// 							Validators.compose([
		// 								FlocksyValidator.required,
		// 								FlocksyValidator.minLengthArray(1),
		// 							]),
		// 						],
		// 					});
		// 				} else {
		// 					stepControl = this._formBuilder.group({
		// 						title: [[]],
		// 					});
		// 				}
		// 			} else if (
		// 				val.data.questions[this.projectTitleId][i][
		// 					'project_question_type_id'
		// 				] == this.oQuestionTypes['file']
		// 			) {
		// 				this.oUploader['step' + step] = new FileUploader({
		// 					url: '',
		// 				});
		// 				if (field_required) {
		// 					stepControl = this._formBuilder.group({
		// 						title: this._formBuilder.array(
		// 							[],
		// 							Validators.required
		// 						),
		// 					});
		// 				} else {
		// 					stepControl = this._formBuilder.group({
		// 						title: this._formBuilder.array([]),
		// 					});
		// 				}
		// 			} else {
		// 				if (field_required) {
		// 					stepControl = this._formBuilder.group({
		// 						title: ['', FlocksyValidator.required],
		// 					});
		// 				} else {
		// 					stepControl = this._formBuilder.group({
		// 						title: ['', false],
		// 					});
		// 				}
		// 			}
		// 			this.marketplaceCategory['questionForms'][
		// 				'steps'
		// 			].addControl('step' + step, stepControl);
		// 			if (i !== 0) {
		// 				this.marketplaceCategory['questionForms'][
		// 					'disabledStep'
		// 				][i + 1] = true;
		// 			}
		// 			if (
		// 				val?.data?.questions[this.projectTitleId]?.length -
		// 					1 ===
		// 				i
		// 			) {
		// 				setTimeout(() => {
		// 					document
		// 						.getElementById('questionContainerGroup')
		// 						.scrollIntoView({
		// 							behavior: 'smooth',
		// 							block: 'start',
		// 							inline: 'nearest',
		// 						});
		// 				}, 500);
		// 			}
		// 		}
		// 	});
	}

	setSelectedType() {
		for (const oType of this.oDefs['titles']) {
			if (this.category['type'] == oType['project_type_id']) {
				this.oSelectedType = oType;
				return;
			}
		}
	}

	setSelectedTitle() {
		if (this.oDefs && this.category['type']) {
			if (this.oDefs['titles']) {
				for (const oTitle of this.oDefs['titles']) {
					if (
						this.category['title'] == oTitle['project_title_id'] &&
						this.category['type'] == oTitle['project_type_id']
					) {
						this.oSelectedTitle = oTitle;
						return;
					}
				}
			}
		}
	}

	initQuestionDefs(
		bFill?: boolean,
		fillQuestionAnswersForTemplatesCallback?,
		isTemplate?
	) {
		this.validateProjectTitle = true;
		if (this.category['title']) {
			this.oDefs['questions'] = {};
			this.oDefs['app_list_strings'] = {};
			this.retrieveDefsQuestion(
				this.category['type'],
				this.category['title']
			).subscribe((data) => {
				this.legacyBrief = data?.data?.is_legacy_brief;
				this.oDefs['questions'] = data.data['questions'];
				this.oDefs['app_list_strings'] = data.data['app_list_strings'];
				this.initQuestions(bFill);
				if (isTemplate) {
					fillQuestionAnswersForTemplatesCallback();
				}
			});
		} else {
			this.validateProjectTitle = false;
		}
	}

	retrieveQuestionAnswers(guid) {
		let data = {};
		if (this.action == 'redo-project') {
			data['action'] = 'redo-project';
		}
		this._projectDetailService.get(guid, data).subscribe(
			(data) => {
				const oProjectsData = data.data;
				this.oProject = oProjectsData['project'];
				this.oProjectsData = oProjectsData;
				this.designersWorked = oProjectsData['project']['designer'];
				this.selectedPackage = oProjectsData['project_package'];
				if (!this.isSubClientUser) {
					this.getAssignedUser(this.oProject?.project_id);
				}
				this.oProject['directions'] = JSON.parse(
					this.oProject['directions']
				);
				if (typeof this.oProject['directions'] == 'string') {
					this.oProject['directions'] = JSON.parse(
						this.oProject['directions']
					);
				}
				if (this.oProject['directions']?.length) {
					this.oProject['directions']?.forEach((resData) => {
						const anchorTagPattern =
							/<a[^>]*href="([^"]*)"[^>]*>(.*?)<\/a>/gi;
						resData['text'] = resData['text'].replace(
							anchorTagPattern,
							(match, href, text) => {
								return href;
							}
						);
						resData['text'] = resData['text'].replace(
							/<br>/g,
							'\n'
						);
						const urlPattern =
							/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
						let textWithLinks = resData?.text?.replace(
							'www.',
							'https://www.'
						);
						textWithLinks = textWithLinks.replace(
							urlPattern,
							(url) => {
								url = url.replace('https://www.', 'www.');
								const fullUrl = url.startsWith('www.')
									? `http://${url}`
									: url;
								return `<a href="${fullUrl}" class="direction-link" target="_blank">${url}</a>`;
							}
						);
						resData['textHtml'] = textWithLinks.replace(
							/\n/g,
							'<br>'
						);
					});
				}
				this.isMarketplaceTab =
					oProjectsData['project']['concept_id'] == 2 ? true : false;
				if ('project_title_id' in oProjectsData['project']) {
					this.oDefs.titles = [];
				}
				if (this.isMarketplaceTab) {
					this.marketplaceCategory['directionList'] =
						this.oProject['directions'];
					this.marketplaceCategory['copyBriefFile'] =
						this.oProject['document_files'];
					this.marketplaceCategory['document_id'] =
						this.oProject['document_id'];
				} else {
					this.category['directionList'] =
						this.oProject['directions'];
					this.category['copyBriefFile'] =
						this.oProject['document_files'];
					this.category['document_id'] = this.oProject['document_id'];
				}
				if (
					this.isProjectManagerUser &&
					this.oProject?.created_by_parent_id
				) {
					this._clientSubClientService
						.getPermissions(
							this.oProject?.operational_id,
							this.oProject?.created_by
						)
						.subscribe((data) => {
							let canManageAllProjects =
								this._clientSubClientService.canManageAllProjects(
									data?.data?.permissions
								);
							let canManageOwnProjects =
								this._clientSubClientService.canManageOwnProjects(
									data?.data?.permissions
								);
							let canViewManageOwnProjects =
								this._clientSubClientService.canViewManageOwnProjects(
									data?.data?.permissions
								);

							if (
								!canManageAllProjects &&
								!canManageOwnProjects &&
								!canViewManageOwnProjects
							) {
								this._toasterService.pop(
									'error',
									"Sorry, you're not able to create projects."
								);
								this._router.navigate(['/projects/active']);
							}
						});
				}
				if (
					oProjectsData.project?.concept_id ==
					this.projectConcepts?.marketplace
				) {
					if ('parent_action' in this.oQueryString) {
						if ('project_type_id' in oProjectsData['project']) {
							this.marketplaceCategory['type'] =
								oProjectsData['project']['project_type_id'];
							this.marketplaceCategory['projectTitle'] =
								oProjectsData['project']['title'];
							this.setMarketplaceSelectedType();
							this.bDisableType = true;
						}
						if ('project_title_id' in oProjectsData['project']) {
							this.marketplaceCategory['title'] =
								oProjectsData['project']['project_title_id'];
							this.setMarketplaceSelectedTitle();
							// this.initMarketplaceQuestion(true);
							this.retrieveSharedBuckets(
								this.oQueryString['parent_id']
							);
							let categoryIndex =
								this.oDefs.marketplaceTitles.findIndex(
									(item) =>
										item.project_title_id ==
										+this.marketplaceCategory[
											'title'
										]
								);
							this.marketplaceCategory['titleName'] =
								this.oDefs.marketplaceTitles[
									categoryIndex
								]?.title;
							let selectedData = {
								...this.oDefs.marketplaceTitles[categoryIndex],
							};
							this.oDefs.marketplaceTitles.splice(
								categoryIndex,
								1
							);
							if (Object.keys(selectedData)?.length) {
								this.oDefs.marketplaceTitles.unshift(
									selectedData
								);
							}
							this.oDefs.titles = this.oDefs.marketplaceTitles;
							this.bDisableTitle = true;
						}
					}
					this.oDefs.titles = this.oDefs.marketplaceTitles.filter(
						(item) =>
							item.project_title_id ==
							+this.marketplaceCategory['selectedTitleId']
					);
				} else {
					if ('parent_action' in this.oQueryString) {
						if ('project_type_id' in oProjectsData['project']) {
							this.category['type'] =
								oProjectsData['project']['project_type_id'];
							this.category['projectTitle'] =
								oProjectsData['project']['title'];
							this.setSelectedType();
							this.bDisableType = true;
						}
						if ('project_title_id' in oProjectsData['project']) {
							this.category['title'] =
								oProjectsData['project']['project_title_id'];
							this.setSelectedTitle();
							this.category.selected =
								this.category['title'] + 'prj';
							this.initQuestionDefs(true);
							this.bDisableTitle = true;
							setTimeout(() => {
								let categoryIndex =
									this.oDefs.subscriptionTitles.findIndex(
										(item) =>
											this.currentTemplateId
												? item.project_title_id ==
												  this.category['title']
												: item.project_title_id ==
														this.category[
															'title'
														] && !item.isTemplate
									);
								this.category['titleName'] =
									this.oDefs.subscriptionTitles[
										categoryIndex
									]?.title;
								let selectedData = {
									...this.oDefs.subscriptionTitles[
										categoryIndex
									],
								};
								this.oDefs.subscriptionTitles.splice(
									categoryIndex,
									1
								);
								if (Object.keys(selectedData)?.length) {
									this.oDefs.subscriptionTitles.unshift(
										selectedData
									);
								}
								this.oDefs.titles =
									this.oDefs.subscriptionTitles;
								// this.category['isSearchFieldDisabled'] = true;
							});
							this.changeDetectorRef.detectChanges();
						}
					}
					this.getSuggestedTeamMembers(true);
					if (this.isProjectManagerUser) {
						this.getPendingCount();
						this.getEligibleActiveProject(
							this.oProject?.created_by
						);
					}
				}
			},
			() => {}
		);
	}

	getExcludedDesigners() {
		this._projectService
			.getExcludedDesigners(this.oQueryString['parent_id'])
			.subscribe((data) => {
				let resData = data.data;
				this.aExcludedTeamMembers = resData.map((res) => res?.id);
			});
	}
	getSuggestedTeamMembers(reset?: boolean) {
		if (
			this.isAdminUser ||
			(this.isProjectManagerUser && !this.bCopyBrief)
		) {
			return false;
		}
		return false;
	}

	getTeamMembers(reset?: boolean) {
		if (
			this.isAdminUser ||
			(this.isProjectManagerUser && !this.bCopyBrief)
		) {
			return false;
		}
		this.aTeamMembers = [];

		this.retrieveTeamMembers().subscribe(
			(data) => {
				setTimeout(() => {
					if (reset) {
						this.aTeamMembers = data.data;
					} else {
						this.aTeamMembers = this.aTeamMembers.concat(data.data);
					}
				});

				if (this.designersWorked && this.designersWorked?.length > 0) {
					this.addDesignersToExcluded();
				}
				this.invitePlatinumTeamMembers();
				this.oTmPagination['totalRecords'] = data?.total_records;
				this.oTmPagination['itemsPerPage'] = data?.items_per_page;
			},
			() => {}
		);
	}

	initFormArray(oFormAppStrings: object) {
		const aAssignForm = [];
		let i = 0;
		for (const appFormListString in oFormAppStrings) {
			aAssignForm[i] = this._formBuilder.group({
				name: appFormListString,
				value: false,
				text: oFormAppStrings[appFormListString],
			});
			i++;
		}
		return aAssignForm;
	}
	initAppListStringWithIdName(
		dropdown_name: string,
		oAppStrings: object
	): void {
		this.aAppListString[dropdown_name] = [];
		for (const appListString in oAppStrings) {
			this.aAppListString[dropdown_name].push({
				id: appListString,
				name: oAppStrings[appListString],
			});
		}
	}

	retrieveTeamMembers() {
		let userId = this._localStorageService.getUserId();
		if (this.isProjectManagerUser && this.bCopyBrief) {
			userId = this.oProject.created_by;
		}
		return this._teamService.getDesignersByClient(
			userId,
			this.prepareTmPagination()
		);
	}

	onChangeSortOrder(event) {
		this.sort_order = event.target.value;
	}

	retrieveDefsQuestion(project_type_id, project_title_id) {
		const oFilter = {};
		if (
			(this.isAdminUser ||
				(this.isProjectManagerUser && !this.bCopyBrief)) &&
			this.statusForAdmin == 'all'
		) {
			oFilter['status'] = this.statusForAdmin;
		}

		let userId = '';
		if (this.isProjectManagerUser && this.bCopyBrief) {
			userId = this.oProject.created_by;
		}

		if (this.bCopyBrief) {
			oFilter['copy_brief'] = 1;
		}

		if (this.action == 'start-project') {
			oFilter['start-project'] = 1;
		}

		let project_id = this.projectId;
		return this._projectService.getDefsQuestion(
			project_type_id,
			project_title_id,
			userId,
			project_id,
			oFilter
		);
	}

	initQuestions(bFill?: boolean) {
		if (this.oDefs['questions'][this.category['title']]) {
			if (this.oDefs['questions'][this.category['title']].length > 0) {
				this.category['questionForms']['totalStep'] =
					this.oDefs['questions'][this.category['title']].length;
				this.category['questionForms']['activeStep'] = 1;
				this.category['questionForms']['steps'] =
					this._formBuilder.group({});
				this.category['questionForms']['steps'].reset();
				for (
					let i = 0;
					i < this.oDefs['questions'][this.category['title']].length;
					i++
				) {
					const step = i + 1;
					let stepControl: FormGroup;
					let field_required = false;
					if (
						this.oDefs['questions'][this.category['title']][i][
							'field_required'
						]
					) {
						field_required = true;
					}

					if (
						(this.oDefs['questions'][this.category['title']][i][
							'project_question_type_id'
						] == this.oQuestionTypes['checkbox'] ||
							this.oDefs['questions'][this.category['title']][i][
								'project_question_type_id'
							] == this.oQuestionTypes.multienum) &&
						this.oDefs['questions'][this.category['title']][i][
							'custom_field'
						] != 'invite_designer'
					) {
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array(
									this.initFormArray(
										this.oDefs['app_list_strings'][
											this.oDefs['questions'][
												this.category['title']
											][i]['field_options']
										]
									),
									Validators.compose([
										Validators.required,
										FlocksyValidator.minLengthCheckbox(1),
									])
								),
							});
						} else {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array(
									this.initFormArray(
										this.oDefs['app_list_strings'][
											this.oDefs['questions'][
												this.category['title']
											][i]['field_options']
										]
									)
								),
							});
						}
					} else if (
						this.oDefs['questions'][this.category['title']][i][
							'project_question_type_id'
						] == this.oQuestionTypes['multienum'] &&
						this.oDefs['questions'][this.category['title']][i][
							'custom_field'
						] == 'invite_designer'
					) {
						this.initAppListStringWithIdName(
							this.oDefs['questions'][this.category['title']][i][
								'field_options'
							],
							this.oDefs['app_list_strings'][
								this.oDefs['questions'][this.category['title']][
									i
								]['field_options']
							]
						);
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: [
									[],
									Validators.compose([
										FlocksyValidator.required,
										FlocksyValidator.minLengthArray(1),
									]),
								],
							});
						} else {
							stepControl = this._formBuilder.group({
								title: [[]],
							});
						}
					} else if (
						this.oDefs['questions'][this.category['title']][i][
							'project_question_type_id'
						] == this.oQuestionTypes['file']
					) {
						this.oUploader['step' + step] = new FileUploader({
							url: '',
						});
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array(
									[],
									Validators.required
								),
							});
						} else {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array([]),
							});
						}
					} else {
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: ['', FlocksyValidator.required],
							});
						} else {
							stepControl = this._formBuilder.group({
								title: ['', false],
							});
						}
					}

					this.category['questionForms']['steps'].addControl(
						'step' + step,
						stepControl
					);
					if (i !== 0) {
						this.category['questionForms']['disabledStep'][i + 1] =
							true;
					}
				}
				this.category['questionForms']['disabledStep'][
					this.oDefs['questions'][this.category['title']].length + 1
				] = true;
			}
		}

		if (bFill) {
			this.fillQuestionAnswers();
			this.retrieveSharedBuckets(this.oQueryString['parent_id']);
			this.getTeamMembers(true);
			if (['start-project', 'update-brief'].includes(this.action)) {
				this.retrieveInvitedDesigners(this.oQueryString['parent_id']);
			}
		}
	}

	setMarketplaceSelectedType() {
		this.oSelectedType = {
			project_type_id: this.oProject?.project_type_id,
			title: this.oProject?.project_type_title,
		};
	}
	setMarketplaceSelectedTitle() {
		if (this.oProject && this.marketplaceCategory['type']) {
			this.oSelectedTitle = {
				project_title_id: this.oProject?.project_title_id,
				project_type_id: this.oProject?.project_type_id,
				title: this.oProject?.project_title_title,
			};
		}
	}

	initMarketplaceQuestion(bFill?: boolean) {
		this.validateProjectTitle = true;
		if (this.marketplaceCategory['title']) {
			this._projectTypeService
				.getMarketplaceQuestions(
					this.marketplaceCategory['type'],
					this.marketplaceCategory['title']
				)
				.subscribe((data) => {
					this.oDefs['questions'] = data?.data['questions'];
					this.marketplaceQuestions =
						data.data['questions'][
							this.marketplaceCategory['title']
						];
					this.initMarketplaceQuestions(bFill);
				});
		} else {
			this.validateProjectTitle = false;
		}
	}

	addDesignersToExcluded() {
		this.designersWorked.map((designer, key) => {
			let index = this.aTeamMembers.findIndex(
				(obj) => obj.id == designer.user_id
			);
			if (index > -1) {
				this.aTeamMembers.splice(index, 1);
			}
		});
	}

	invitePlatinumTeamMembers() {
		const aTeams =
			this.category['questionForms']['steps']?.controls['step1']
				?.controls['title']?.value;

		this.aTeamMembers.forEach((designer) => {
			if (
				(designer.creative_level_id ==
					this.creativeLevels['platinum'] ||
					designer.creative_level_id ==
						this.creativeLevels['platinum-lead']) &&
				designer?.is_default_invite &&
				!aTeams?.includes(designer.id)
			) {
				aTeams.push(designer.id);
				this.platinumInvitedDesigners.push(designer.id);
			}
		});
	}

	prepareTmPagination() {
		let oParam = {
			project_type_id: this.category['type'],
			client_designers_block: {
				project_title_id: this.category['title'],
			},
			pageId: 'post-project',
		};
		if (this.bCopyBrief) {
			if (this.oQueryString && 'parent_id' in this.oQueryString) {
				oParam['exclude_project_id'] = this.oQueryString['parent_id'];
			}
		}
		return {
			page: this.oTmPagination['currentPage'],
			filters: JSON.stringify(oParam),
		};
	}

	initMarketplaceQuestions(bFill?: boolean) {
		if (this.marketplaceQuestions) {
			if (this.marketplaceQuestions?.length > 0) {
				this.marketplaceCategory['questionForms']['totalStep'] =
					this.marketplaceQuestions?.length;
				this.marketplaceCategory['questionForms']['activeStep'] = 1;
				this.marketplaceCategory['questionForms']['steps'] =
					this._formBuilder.group({});
				this.marketplaceCategory['questionForms']['steps'].reset();
				for (let i = 0; i < this.marketplaceQuestions?.length; i++) {
					const step = i + 1;
					let stepControl: FormGroup;
					let field_required = false;
					if (this.marketplaceQuestions[i]['field_required']) {
						field_required = true;
					}

					if (
						this.marketplaceQuestions[i][
							'project_question_type_id'
						] == this.oQuestionTypes['file']
					) {
						this.oUploader['step' + step] = new FileUploader({
							url: '',
						});
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array(
									[],
									Validators.required
								),
							});
						} else {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array([]),
							});
						}
					} else {
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: ['', FlocksyValidator.required],
							});
						} else {
							stepControl = this._formBuilder.group({
								title: ['', false],
							});
						}
					}
					this.marketplaceCategory['questionForms'][
						'steps'
					].addControl('step' + step, stepControl);
					if (i !== 0) {
						this.marketplaceCategory['questionForms'][
							'disabledStep'
						][i + 1] = true;
					}
				}
				this.marketplaceCategory['questionForms']['disabledStep'][
					this.marketplaceQuestions?.length + 1
				] = true;
			}
		}

		if (bFill) {
			this.fillMarketplaceQuestionAnswers();
			this.retrieveSharedBuckets(this.oQueryString['parent_id']);
		}
	}

	retrieveSharedBuckets(guid) {
		this._projectBucketService.getSharedBuckets(guid).subscribe((data) => {
			const aFillBucketData = [];
			for (const oData of data.data) {
				if (this.isMarketplaceTab) {
					this.marketplaceCategory['selectedBucket'].push(
						oData['id']
					);
					this._bucketService.selectedBucket.next(
						this.marketplaceCategory['selectedBucket']
					);
				} else {
					this.category['selectedBucket'].push(oData['id']);
					this._bucketService.selectedBucket.next(
						this.category['selectedBucket']
					);
				}
				aFillBucketData.push(oData['id']);
			}

			if (this.category['title'] || this.marketplaceCategory['title']) {
				if (this.oDefs['questions'] &&
					(this.oDefs['questions'][this.category['title']] ||
					this.oDefs['questions'][this.marketplaceCategory['title']])
				) {
					if (
						this.oDefs['questions'][this.category['title']]
							?.length > 0
					) {
						for (
							let k = 0;
							k <
							this.oDefs['questions'][this.category['title']]
								.length;
							k++
						) {
							if (
								'custom_field' in
								this.oDefs['questions'][this.category['title']][
									k
								]
							) {
								if (
									this.oDefs['questions'][
										this.category['title']
									][k]['custom_field'] == 'bucket'
								) {
									this.category['questionForms'][
										'steps'
									].controls['step' + (k + 1)].controls[
										'title'
									].setValue(aFillBucketData);
									return;
								}
							}
						}
					} else if (
						this.oDefs['questions'][
							this.marketplaceCategory['title']
						]?.length > 0
					) {
						for (
							let k = 0;
							k <
							this.oDefs['questions'][
								this.marketplaceCategory['title']
							].length;
							k++
						) {
							if (
								'custom_field' in
								this.oDefs['questions'][
									this.marketplaceCategory['title']
								][k]
							) {
								if (
									this.oDefs['questions'][
										this.marketplaceCategory['title']
									][k]['custom_field'] == 'bucket'
								) {
									this.marketplaceCategory['questionForms'][
										'steps'
									].controls['step' + (k + 1)]?.controls[
										'title'
									]?.setValue(aFillBucketData);
									return;
								}
							}
						}
					}
				}
			}
		});
	}

	fillQuestionAnswers() {
		this.fillProjectQuestions(
			this.oProjectsData['project_question_answers']
		);
		if (this.category['title']) {
			if (this.oDefs['questions'][this.category['title']]) {
				if (
					this.oDefs['questions'][this.category['title']]?.length > 0
				) {
					for (
						let k = 0;
						k <
						this.oDefs['questions'][this.category['title']]?.length;
						k++
					) {
						if (
							'custom_field' in
							this.oDefs['questions'][this.category['title']][k]
						) {
							if (
								this.oDefs['questions'][this.category['title']][
									k
								]['custom_field'] == 'title'
							) {
								this.category['questionForms'][
									'steps'
								].controls['step' + (k + 1)].controls[
									'title'
								].setValue(
									this.oProjectsData['project']['title']
								);
								continue;
							} else if (
								this.oDefs['questions'][this.category['title']][
									k
								]['custom_field'] == 'bucket'
							) {
								continue;
							} else if (
								this.oDefs['questions'][this.category['title']][
									k
								]['custom_field'] == 'invite_designer'
							) {
								continue;
							}
						}
						if (
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 1 ||
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 6 ||
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 2 ||
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 3
						) {
							if (
								this.oDefs['questions'][this.category['title']][
									k
								]['project_question_id'] in
								this.oProjectQuestion
							) {
								this.category['questionForms'][
									'steps'
								].controls['step' + (k + 1)].controls[
									'title'
								].setValue(
									this.oProjectQuestion[
										this.oDefs['questions'][
											this.category['title']
										][k]['project_question_id']
									]['answer']
								);
							}
						} else if (
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 4
						) {
							if (
								this.oProjectQuestion[
									this.oDefs['questions'][
										this.category['title']
									][k]['project_question_id']
								]['answer']
							) {
								const aCheckbox =
									this.oProjectQuestion[
										this.oDefs['questions'][
											this.category['title']
										][k]['project_question_id']
									]['answer'].split(',');
								aCheckbox.forEach((res) => {
									this.category['questionForms'][
										'steps'
									].controls['step' + (k + 1)]?.controls[
										'title'
									].controls[res].controls['value'].setValue(
										true
									);
								});
							}
						} else if (
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 5
						) {
							if (
								this.oDefs['questions'][this.category['title']][
									k
								]['project_question_id'] in
								this.oProjectQuestion
							) {
								if (
									this.oProjectQuestion[
										this.oDefs['questions'][
											this.category['title']
										][k]['project_question_id']
									]['answer']
								) {
									const aCheckbox =
										this.oProjectQuestion[
											this.oDefs['questions'][
												this.category['title']
											][k]['project_question_id']
										]['answer'].split(',');
									let j = 0;
									for (const oCheckbox of this.category[
										'questionForms'
									]['steps'].controls['step' + (k + 1)].get(
										'title'
									).controls) {
										if (
											aCheckbox.indexOf(
												this.category['questionForms'][
													'steps'
												].controls['step' + (k + 1)]
													.get('title')
													.controls[j].get('name')
													.value
											) != -1
										) {
											this.category['questionForms'][
												'steps'
											].controls['step' + (k + 1)]
												.get('title')
												.controls[j].get('value')
												.setValue(true);
										}
										j++;
									}
								}
							}
						} else if (
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 7
						) {
							this.oCopyBriefFiles['step' + (k + 1)] =
								this.oProjectQuestion[
									this.oDefs['questions'][
										this.category['title']
									][k]['project_question_id']
								]['document_files'];
						}
					}
				}
			}
		}
	}

	fillQuestionAnswersForTemplates() {
		// let modifiedAnswers = [];
		for (
			let k = 0;
			k < this.oDefs['questions'][this.category['title']]?.length;
			k++
		) {
			let modifiedAnswers = [];
			if (
				this.oProjectsData['project_question_answers'][k]
					.project_question_type_id === 5
			) {
				this.oProjectsData['project_question_answers'][k].title =
					'Sizes';
				this.oProjectsData['project_question_answers'][k].answer.map(
					(answer, index) => {
						if (answer.value) {
							modifiedAnswers.push(index);
						}
					}
				);
				this.oProjectsData['project_question_answers'][k].answer =
					modifiedAnswers.join(',');
			} else
				modifiedAnswers =
					this.oProjectsData['project_question_answers'];
		}

		this.fillProjectQuestions(
			this.oProjectsData['project_question_answers']
		);
		for (
			let k = 0;
			k < this.oDefs['questions'][this.category['title']]?.length;
			k++
		) {
			if (
				'custom_field' in
				this.oDefs['questions'][this.category['title']][k]
			) {
				if (
					this.oDefs['questions'][this.category['title']][k][
						'custom_field'
					] == 'title'
				) {
					this.category['questionForms']['steps'].controls[
						'step' + (k + 1)
					].controls['title'].setValue(
						this.oProjectsData['project']['title']
					);
					continue;
				} else if (
					this.oDefs['questions'][this.category['title']][k][
						'custom_field'
					] == 'bucket'
				) {
					continue;
				} else if (
					this.oDefs['questions'][this.category['title']][k][
						'custom_field'
					] == 'invite_designer'
				) {
					continue;
				}
			}
			if (
				this.oDefs['questions'][this.category['title']][k][
					'project_question_type_id'
				] == 1 ||
				this.oDefs['questions'][this.category['title']][k][
					'project_question_type_id'
				] == 6 ||
				this.oDefs['questions'][this.category['title']][k][
					'project_question_type_id'
				] == 2 ||
				this.oDefs['questions'][this.category['title']][k][
					'project_question_type_id'
				] == 3
			) {
				if (
					this.oDefs['questions'][this.category['title']][k][
						'project_question_id'
					] in this.oProjectQuestion
				) {
					this.category['questionForms']['steps'].controls[
						'step' + (k + 1)
					].controls['title'].setValue(
						this.oProjectQuestion[
							this.oDefs['questions'][this.category['title']][k][
								'project_question_id'
							]
						]['answer']
					);
				}
			} else if (
				this.oDefs['questions'][this.category['title']][k][
					'project_question_type_id'
				] == 4
			) {
				if (
					this.oProjectQuestion[
						this.oDefs['questions'][this.category['title']][k][
							'project_question_id'
						]
					]['answer']
				) {
					const aCheckbox =
						this.oProjectQuestion[
							this.oDefs['questions'][this.category['title']][k][
								'project_question_id'
							]
						]['answer'];
					aCheckbox.forEach((res) => {
						this.category['questionForms']['steps'].controls[
							'step' + (k + 1)
						]?.controls['title'].controls[res].controls[
							'value'
						].setValue(true);
					});
				}
			} else if (
				this.oDefs['questions'][this.category['title']][k][
					'project_question_type_id'
				] == 5
			) {
				if (
					this.oDefs['questions'][this.category['title']][k][
						'project_question_id'
					] in this.oProjectQuestion
				) {
					if (
						this.oProjectQuestion[
							this.oDefs['questions'][this.category['title']][k][
								'project_question_id'
							]
						]['answer']
					) {
						const aCheckbox =
							this.oProjectQuestion[
								this.oDefs['questions'][this.category['title']][
									k
								]['project_question_id']
							]['answer'].split(',');
						let j = 0;
						for (const oCheckbox of this.category['questionForms'][
							'steps'
						].controls['step' + (k + 1)].get('title').controls) {
							if (
								aCheckbox.indexOf(
									this.category['questionForms'][
										'steps'
									].controls['step' + (k + 1)]
										.get('title')
										.controls[j].get('name').value
								) != -1
							) {
								this.category['questionForms'][
									'steps'
								].controls['step' + (k + 1)]
									.get('title')
									.controls[j].get('value')
									.setValue(true);
							}
							j++;
						}
					}
				}
			} else if (
				this.oDefs['questions'][this.category['title']][k][
					'project_question_type_id'
				] == 7
			) {
				this.oCopyBriefFiles['step' + (k + 1)] =
					this.oProjectQuestion[
						this.oDefs['questions'][this.category['title']][k][
							'project_question_id'
						]
					]['document_files'];
			}
		}
	}

	fillProjectQuestions(aData) {
		this.customSizesArray = [];
		for (const oData of aData) {
			if (oData['project_question_id']) {
				this.oProjectQuestion[oData['project_question_id']] = oData;
			}

			if (oData['title'] == 'Sizes' && oData['answer_options']) {
				if (typeof oData['answer_options'] === 'string') {
					try {
						oData['answer_options'] = JSON.parse(
							oData['answer_options']
						);
					} catch (error) {
						console.error('Error parsing answer_options:', error);
						continue;
					}
				}

				this.customSizeEnabled = oData['answer_options']['custom_size']
					? true
					: false;

				if (Array.isArray(oData['answer_options'].options) && 
					oData['answer_options'].options.length > 0 && 
					!Array.isArray(oData['answer_options'].options[0])) {
					oData['answer_options'].options = [oData['answer_options'].options];
				}

				oData['answer_options']?.options.forEach((ansArray) => {
					let customWidth = null;
					let customHeight = null;
					let customType = '';
					let checked = false;

					ansArray.forEach((ans) => {
						if (ans?.key == 'height') {
							customHeight = ans?.value;
						} else if (ans?.key == 'width') {
							customWidth = ans?.value;
						} else if (ans?.key == 'dimension') {
							customType = ans?.value;
						} else if (ans?.key == 'checked') {
							checked = ans.value == true ? true : false;
						}
					});

					if (!ansArray.some((ans) => ans?.key === 'checked')) {
						checked = true;
					}

					if (customWidth && customHeight && customType) {
						this.customSizesArray.push({
							customWidth: customWidth,
							customHeight: customHeight,
							customType: customType,
							selected: checked,
						});
					}
				});
			}
		}

		this.checkIfCustomSizeEnabled();
	}

	retrieveInvitedDesigners(guid) {
		this.retrieveTeamMembers().subscribe((data) => {
			this.aTeamMembers = data?.data;
			this.oTmPagination['totalRecords'] = data?.total_records;
			this.oTmPagination['itemsPerPage'] = data?.items_per_page;

			this._projectBidService.getInvited(guid, {}).subscribe((data) => {
				const aFillInvitedData = this.fillValueToSingleArray(data.data);
				this.aPayloadInviteDesigner = this.fillValueToSingleArray(
					data.data
				);

				if (this.category['title']) {
					if (this.oDefs['questions'][this.category['title']]) {
						if (
							this.oDefs['questions'][this.category['title']]
								.length > 0
						) {
							for (
								let k = 0;
								k <
								this.oDefs['questions'][this.category['title']]
									.length;
								k++
							) {
								if (
									'custom_field' in
									this.oDefs['questions'][
										this.category['title']
									][k]
								) {
									if (
										this.oDefs['questions'][
											this.category['title']
										][k]['custom_field'] ==
										'invite_designer'
									) {
										this.category['questionForms'][
											'steps'
										].controls['step' + (k + 1)].controls[
											'title'
										].setValue(aFillInvitedData);
										return;
									}
								}
							}
						}
					}
				}
			});
		});
	}

	fillValueToSingleArray(aData) {
		const aResult = [];
		for (const oData of aData) {
			aResult.push(oData['id']);
		}
		return aResult;
	}

	fillMarketplaceQuestionAnswers() {
		this.fillProjectQuestions(
			this.oProjectsData['project_question_answers']
		);
		if (this.marketplaceCategory['title']) {
			if (this.marketplaceQuestions) {
				if (this.marketplaceQuestions.length > 0) {
					for (let k = 0; k < this.marketplaceQuestions.length; k++) {
						if ('custom_field' in this.marketplaceQuestions[k]) {
							if (
								this.marketplaceQuestions[k]['custom_field'] ==
								'title'
							) {
								this.marketplaceCategory['questionForms'][
									'steps'
								].controls['step' + (k + 1)].controls[
									'title'
								].setValue(
									this.oProjectsData['project']['title']
								);
								continue;
							} else if (
								this.marketplaceQuestions[k]['custom_field'] ==
								'bucket'
							) {
								continue;
							} else if (
								this.marketplaceQuestions[k]['custom_field'] ==
								'invite_designer'
							) {
								continue;
							}
						}
						if (
							this.marketplaceQuestions[k][
								'project_question_type_id'
							] == 1 ||
							this.marketplaceQuestions[k][
								'project_question_type_id'
							] == 6 ||
							this.marketplaceQuestions[k][
								'project_question_type_id'
							] == 2 ||
							this.marketplaceQuestions[k][
								'project_question_type_id'
							] == 3
						) {
							if (
								this.marketplaceQuestions[k][
									'project_question_id'
								] in this.oProjectQuestion
							) {
								this.marketplaceCategory['questionForms'][
									'steps'
								].controls['step' + (k + 1)].controls[
									'title'
								].setValue(
									this.oProjectQuestion[
										this.marketplaceQuestions[k][
											'project_question_id'
										]
									]['answer']
								);
							}
						} else if (
							this.marketplaceQuestions[k][
								'project_question_type_id'
							] == 4
						) {
							if (
								this.marketplaceQuestions[k][
									'project_question_id'
								] in this.oProjectQuestion
							) {
								if (
									this.oProjectQuestion[
										this.marketplaceQuestions[k][
											'project_question_id'
										]
									]['answer']
								) {
									const aCheckbox =
										this.oProjectQuestion[
											this.marketplaceQuestions[k][
												'project_question_id'
											]
										]['answer'].split(',');
									this.marketplaceCategory['questionForms'][
										'steps'
									].controls['step' + (k + 1)]?.controls[
										'title'
									].setValue(aCheckbox);
								}
							}
						} else if (
							this.marketplaceQuestions[k][
								'project_question_type_id'
							] == 5
						) {
							if (
								this.marketplaceQuestions[k][
									'project_question_id'
								] in this.oProjectQuestion
							) {
								if (
									this.oProjectQuestion[
										this.marketplaceQuestions[k][
											'project_question_id'
										]
									]['answer']
								) {
									const aCheckbox =
										this.oProjectQuestion[
											this.marketplaceQuestions[k][
												'project_question_id'
											]
										]['answer'].split(',');
									let j = 0;
									for (const oCheckbox of this.marketplaceCategory[
										'questionForms'
									]['steps'].controls['step' + (k + 1)].get(
										'title'
									).controls) {
										if (
											aCheckbox.indexOf(
												this.marketplaceCategory[
													'questionForms'
												]['steps'].controls[
													'step' + (k + 1)
												]
													.get('title')
													.controls[j].get('name')
													.value
											) != -1
										) {
											this.marketplaceCategory[
												'questionForms'
											]['steps'].controls[
												'step' + (k + 1)
											]
												.get('title')
												.controls[j].get('value')
												.setValue(true);
										}
										j++;
									}
								}
							}
						} else if (
							this.marketplaceQuestions[k][
								'project_question_type_id'
							] == 7
						) {
							this.oCopyBriefFiles['step' + (k + 1)] =
								this.oProjectQuestion[
									this.marketplaceQuestions[k][
										'project_question_id'
									]
								]['document_files'];
						}
					}
				}
			}
		}
	}

	getConfig(question: any) {
		Object.assign(this.config, {
			placeholder: question.question_placeholder,
		});
		return this.config;
	}

	unsetQueFile(name, index) {
		let control;
		if (
			(this.hasMarketplaceAccess || this.selectedPackage) &&
			this.marketplaceCategory['questionForms']['steps']
		) {
			control = <FormArray>(
				this.marketplaceCategory['questionForms']['steps']?.controls[
					name
				]?.controls['title']
			);
		} else {
			control = <FormArray>(
				this.category['questionForms']['steps']?.controls[name]
					?.controls['title']
			);
		}
		control.removeAt(index);
		document.getElementById('que_file_' + name)['value'] = '';
	}

	unsetCopyDoc(name, index) {
		if (this.oCopyBriefFiles[name]) {
			if (index !== -1) {
				this.oCopyBriefFiles[name].splice(index, 1);
			}
		}
	}

	onSingleQueFileSelected(event, name) {
		let control: FormArray;
		if (
			(this.hasMarketplaceAccess || this.selectedPackage) &&
			this.marketplaceCategory['questionForms']['steps']
		) {
			control = <FormArray>(
				this.marketplaceCategory['questionForms']['steps'].controls[
					name
				].controls['title']
			);
		} else {
			control = <FormArray>(
				this.category['questionForms']['steps'].controls[name].controls[
					'title'
				]
			);
		}
		let filesProcessed = 0;

		for (const oFile of event.target.files) {
			let index = -1;
			for (let i = 0; i < control.length; i++) {
				if (control.at(i).value.name === oFile.name) {
					index = i;
					break;
				}
			}

			if (index === -1) {
				this.fileLoaderShown = true;
				if (oFile.type.startsWith('image/')) {
					var reader = new FileReader();
					reader.readAsDataURL(oFile);
					reader.onload = (events: any) => {
						filesProcessed++;
						control.push(
							this._formBuilder.group({
								file: [oFile, ''],
								name: oFile.name,
								imgUrl: events.target.result,
							})
						);
						if (event.target.files.length === filesProcessed) {
							setTimeout(() => {
								this.fileLoaderShown = false;
								this.fileUploadInput.nativeElement.value = '';
							});
						}
					};
				} else {
					filesProcessed++;
					// Non-image files
					control.push(
						this._formBuilder.group({
							file: [oFile, ''],
							name: oFile.name,
							// For non-image files, you may not need imgUrl or set it to null
							imgUrl: null,
						})
					);
					if (event.target.files.length === filesProcessed) {
						this.fileLoaderShown = false;
						this.fileUploadInput.nativeElement.value = '';
					}
				}
			} else {
				this.fileLoaderShown = false;
			}
		}
	}

	imgFileShown(oFile) {
		var reader = new FileReader();
		reader.readAsDataURL(oFile?.value?.file);
		return (reader.onload = (event: any) => {
			return event.target.result;
		});
	}

	initDesignerProjectModal(
		oDesignerUser,
		template: TemplateRef<any>,
		eventId: any,
		modalType
	) {
		this.isEligibleForHire = oDesignerUser.is_eligible_hire_team_member;
		this.modalDesignerProjectRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{ modalClass: 'modal-dialog', class: 'modal-xl modal-full' }
			)
		);
		this.oDesignerUser = oDesignerUser;
		this._analyticsService.setOverlayTrackData(
			17,
			eventId,
			this.oDesignerUser['id'],
			1
		);
		this.aDesignerProjects = [];
		this.activeModalType = modalType;
	}

	onClickLoadMorePackage() {
		this.loadMorePagination['currentPage'] += 1;
		this.getMarketplacePackages();
	}

	redirectForUpgradePlan() {
		if (!this.isUpdatedBilling) {
			this._router.navigate(['/plans/billing-info']);
		} else {
			this._router.navigate(['/plans']);
		}
	}

	fetchPackageInfo(packageInfo) {
		this.packageInfo = packageInfo;
		this.openPackageDetailedInfo(this.packageDetailedInfoTemp);
	}

	openPackageDetailedInfo(template) {
		this.modalDesignerProjectRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{ modalClass: 'modal-dialog', class: 'modal-xl modal-full' }
			)
		);
	}

	fileDetail(event) {
		if (this.isMarketplaceTab) {
			this.marketplaceCategory['uploadedFile'] = event;
		} else {
			this.category['uploadedFile'] = event;
		}
	}

	filterData() {
		this.isCarouselSkeletonLoading = false;
		this.oDefs.titles = [];
		setTimeout(() => {
			if (
				(this.category['searchableText'] && !this.isMarketplaceTab) ||
				(this.marketplaceCategory['searchableText'] &&
					this.isMarketplaceTab)
			) {
				setTimeout(() => {
					if (!this.isMarketplaceTab) {
						this.oDefs.titles =
							this.oDefs.subscriptionTitles.filter((item) => {
								const searchableText =
									this.category[
										'searchableText'
									].toLowerCase();
								const subCategories = CATEGORIES.reduce(
									(acc, curr) => {
										const matchingSubcategories =
											curr.subcategories.filter((subC) =>
												subC.keywords.some((keyword) =>
													keyword
														.toLowerCase()
														.includes(
															searchableText
														)
												)
											);
										if (matchingSubcategories.length > 0) {
											acc.push({
												...curr,
												subcategories:
													matchingSubcategories,
											});
										}
										return acc;
									},
									[]
								);

								const hasMatchingTitle = subCategories.some(
									(subCategory) => {
										const subCategoryTitles =
											subCategory.subcategories.map(
												(sub) => sub.title.toLowerCase()
											);
										const match =
											subCategoryTitles.includes(
												item.title.toLowerCase()
											);
										return match;
									}
								);

								return (
									item.title
										.toLowerCase()
										.includes(searchableText) ||
									(Object.keys(item?.type).length > 0 &&
										item.type.title
											.toLowerCase()
											.includes(searchableText)) ||
									(item?.project_sub_category?.type &&
										item.project_sub_category.type.title
											.toLowerCase()
											.includes(searchableText)) ||
									hasMatchingTitle
								);
							});
					} else {
						this.oDefs.titles = this.oDefs.marketplaceTitles.filter(
							(item) =>
								item.title
									.toLowerCase()
									.includes(
										this.marketplaceCategory[
											'searchableText'
										].toLowerCase()
									) ||
								item.type.title
									.toLowerCase()
									.includes(
										this.marketplaceCategory[
											'searchableText'
										].toLowerCase()
									)
						);
					}
					this.isCarouselSkeletonLoading = true;
				});
			} else {
				if (!this.isMarketplaceTab) {
					this.oDefs.titles = [...this.oDefs.subscriptionTitles];
				} else {
					this.oDefs.titles = [...this.oDefs.marketplaceTitles];
				}
				this.isCarouselSkeletonLoading = true;
			}
		});
	}

	filterByCategories(keyChanged = 'seeAll') {

		this.isCarouselSkeletonLoading = true;
		const titlesToFilter = this.polyfilledStructuredClone(
			this.oDefs.subscriptionTitles
		);
		
		const formControls = this.filterCategoriesForm.controls;
		if (keyChanged === 'seeAll' && formControls.seeAll.value === true) {
			Object.entries(formControls).forEach(([key, control]) => {
				if (key !== 'seeAll' && key !== 'templates' && control.enabled) {
					control.setValue(true, { emitEvent: false });
				}
			})
		}
		else if (keyChanged === 'seeAll' && formControls.seeAll.value === false) {
			Object.entries(formControls).forEach(([key, control]) => {
				if (key !== 'seeAll' && key !== 'templates' && control.enabled) {
					control.setValue(false, { emitEvent: false });
				}
			})
		}

		// Update 'See All' checkbox based on other checkboxes' status
		this.updateSeeAllCheckbox(formControls, keyChanged);
		this.previousCategoryFilters = this.filterCategoriesForm.getRawValue();
		this.oDefs.titles = [];
		let arrAccumulator = [];
		let templatesAccumulator = [];
		let filteredTitles = [];

		// If 'See All' is selected, return all titles
		// Define a mapping between form controls and category IDs, including templates
		const categoryMapping = this.categoryMapping;

		// Filter based on selected categories
		for (const [key, categoryId] of Object.entries(categoryMapping)) {
			if (formControls[key].value) {
				if (key === 'seeAll') {
					continue;
				}
				// Templates Only
				if (key === 'templates') {
					arrAccumulator = titlesToFilter.filter(
						(item) => item.isTemplate
					);
					break;
				}

				templatesAccumulator = templatesAccumulator.concat(
					this.filterTitlesByCategory(
						categoryId,
						titlesToFilter,
						true
					)
				);

				arrAccumulator = arrAccumulator.concat(
					this.filterTitlesByCategory(
						categoryId,
						titlesToFilter,
						false
					)
				);
			}

			this.isTemplatesPresentInCategory = !!templatesAccumulator.length;
			this.checkIfTemplatesPresent();
			if (!formControls['templates'].value) {
				filteredTitles = arrAccumulator;
			} else {
				filteredTitles = templatesAccumulator.concat(arrAccumulator);
			}
		}
		this.isAnyCategoryCheckboxSelected = this.applyAnySelectedCategoryIcon(
			this.filterCategoriesForm.getRawValue()
		);
		this.updateTitles(filteredTitles);
	}

	private updateSeeAllCheckbox(formControls, keyChanged) {
		// If 'See All' is checked and it's the one that changed, do nothing
		if (formControls['seeAll'].value && keyChanged === 'seeAll') {
			return;
		} else {
			const enabledControls = Object.keys(formControls).filter(
				(key) => key !== 'seeAll' && key !== 'templates' && formControls[key].enabled
			  );
			// If all other checkboxes are checked, check 'See All'
			const allChecked = enabledControls.every((key) => formControls[key].value);

			if (allChecked) {
				formControls['seeAll'].setValue(true, { emitEvent: false });
				return;
			}

			// If any checkbox is checked (but not all), uncheck 'See All'
			const anyChecked = enabledControls.some((key) => formControls[key].value);

			if (anyChecked) {
				formControls['seeAll'].setValue(false, { emitEvent: false });
			}
		}
	}

	private filterTitlesByCategory(categoryId, titlesToFilter, isTemplate) {
		// Filter for other categories
		if (categoryId === 'seeAll') {
			return titlesToFilter.filter(
				(item) => !!item?.isTemplate === isTemplate
			);
		}
		return titlesToFilter.filter(
			(item) =>
				(item?.project_type_id === categoryId ||
					item?.project_sub_category?.project_type_id ===
						categoryId) &&
				!!item?.isTemplate === isTemplate
		);
	}

	private updateTitles(titles) {
		this.titlesSubject.next(titles);
	}
	
	private performUpdateTitles(titles) {
		this.changeDetectorRef.detectChanges();
		setTimeout(() => {
			this.oDefs.titles = titles;
			if (!this.oDefs.titles.length) {
				this.clearAllFields();
			}
		});
	}

	getProjectCategoryTitle(id) {
		for (const oType of this.oDefs['titles']) {
			if (id == oType['project_type_id']) {
				return oType['title'];
			}
		}
	}

	onClickAddTeamMember(
		name: string,
		user_id: number,
		creativeLevelId: number
	) {
		// const aTeams =
		// 	this.category['questionForms']['steps'].controls[name].controls[
		// 		'title'
		// ].value;
		if (this.aExcludedTeamMembers.indexOf(user_id) > -1) {
			this.onClickRemoveExcludeTeamMember(user_id);
		}

		if (this.aPayloadInviteDesigner.indexOf(user_id) == -1) {
			this.aPayloadInviteDesigner.push(user_id);
			if (
				creativeLevelId == this.creativeLevels['platinum'] ||
				creativeLevelId == this.creativeLevels['platinum-lead']
			) {
				this.platinumInvitedDesigners.push(user_id);
			}
		}

		if (this.modalDesignerProjectRef) {
			this.modalDesignerProjectRef.hide();
		}
	}

	onClickRemoveTeamMember(name: string, user_id: number) {
		// const aTeams =
		// 	this.category['questionForms']['steps'].controls[name].controls[
		// 		'title'
		// 	].value;

		const index = this.aPayloadInviteDesigner.indexOf(user_id);
		const platinumIndex = this.platinumInvitedDesigners.indexOf(user_id);
		if (index > -1) {
			this.aPayloadInviteDesigner.splice(index, 1);
		}

		if (platinumIndex > -1) {
			this.platinumInvitedDesigners.splice(index, 1);
		}

		if (this.modalDesignerProjectRef) {
			this.modalDesignerProjectRef.hide();
		}
	}

	onClickAddExcludeTeamMember(user_id: number) {
		// const aTeams =
		// 	this.category['questionForms']['steps'].controls['step1'].controls[
		// 		'title'
		// 	].value;
		if (this.aPayloadInviteDesigner.indexOf(user_id) > -1) {
			this.onClickRemoveTeamMember('step1', user_id);
		}

		if (this.aExcludedTeamMembers.indexOf(user_id) == -1) {
			this.aExcludedTeamMembers.push(user_id);
		}
	}

	onClickRemoveExcludeTeamMember(user_id: number) {
		const index = this.aExcludedTeamMembers.indexOf(user_id);
		if (index > -1) {
			this.aExcludedTeamMembers.splice(index, 1);
		}
	}

	initNotes(designer, template: TemplateRef<any>) {
		this.oDesignerUser = designer;
		this.modalNotesRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{ modalClass: 'modal-dialog', class: 'modal-lg modal-full' }
			)
		);
		this._analyticsService.setOverlayTrackData(
			17,
			92,
			this.oDesignerUser['id'],
			1
		);
	}

	initStats(designer, template: TemplateRef<any>) {
		this.oDesignerUser = designer;
		this.modalStatsRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{ modalClass: 'modal-dialog', class: 'modal-lg modal-full' }
			)
		);

		this._analyticsService.setOverlayTrackData(
			17,
			94,
			this.oDesignerUser['id'],
			1
		);
	}

	private updateItemsPerSlide(windowWidth: number) {
		if (windowWidth >= 1400 && windowWidth <= 1920) {
			this.itemsPerSlide = 5;
		} else if (windowWidth >= 1250 && windowWidth < 1400) {
			this.itemsPerSlide = 4;
		} else if (windowWidth >= 950 && windowWidth < 1250) {
			this.itemsPerSlide = 3;
		} else if (windowWidth >= 678 && windowWidth < 950) {
			this.itemsPerSlide = 2;
		} else if (windowWidth < 678) {
			this.itemsPerSlide = 1;
		}
	}

	updatedDirectionList(event) {
		if (this.isMarketplaceTab) {
			this.marketplaceCategory['directionList'] = event;
		} else {
			this.category['directionList'] = event;
		}
	}

	selectedBucketDetail(event) {
		if (this.isMarketplaceTab) {
			this.marketplaceCategory['selectedBucket'] = event;
		} else {
			this.category['selectedBucket'] = event;
		}
	}

	saveAsDraft(
		$event,
		templateDetails: { isTemplate: boolean; method: string } = {
			isTemplate: false,
			method: null,
		}
	) {
		if (templateDetails.method === 'update') {
			this.oDefs.titles[this.category.currentIndex].title =
				this.templateName.value;
			this.category.titleName = this.templateName.value;
		}
		this.bDisableBtn = true;
		this._projectService.isSaveProject.next(true);
		this.marketplaceCategory['projectTitle'] =
			this.marketplaceCategory['projectTitle'].trim();
		this.category['projectTitle'] = this.category['projectTitle'].trim();
		if (this.isMarketplaceTab) {
			if (this.marketplaceCategory['projectTitle'].length === 0) {
				this.isValidationShown = true;
				this.scrollToInvalidControl();
				this.bDisableBtn = false;
				return;
			}
		} else {
			if (this.category['projectTitle'].length === 0) {
				this.isValidationShown = true;
				this.scrollToInvalidControl();
				this.bDisableBtn = false;
				return;
			}
		}
		setTimeout(() => {
			$event && $event.preventDefault();
			this.draftParams = !templateDetails.isTemplate ? 5 : undefined;
			if (
				(this.selectedPackage || this.marketplaceCategory['type']) &&
				this.hasMarketplaceAccess &&
				this.isMarketplaceTab
			) {
				this.onMarketplaceQuestionsSubmit(this.draftParams);
			} else {
				this.onQuestionSubmit(this.draftParams, templateDetails);
			}
		}, 1000);
	}

	initSaveTemplateModal(template: TemplateRef<any>) {
		this.modalSaveAsTemplateModal = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{
					modalClass: 'modal-dialog',
					class: 'modal-flocksy-lg modal-flocksy-md modal-flocksy-sm',
				}
			)
		);
	}
	initUpdateTemplateModal(template: TemplateRef<any>) {
		this.templateName.setValue(this.category.titleName);
		this.modalUpdateTemplateModal = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{
					modalClass: 'modal-dialog',
					class: 'modal-flocksy-lg modal-flocksy-md modal-flocksy-sm',
				}
			)
		);
	}

	getTemplateById(id: number) {
		this.currentTemplateId = id;
		this._projectService.getTemplateById(id).subscribe((res) => {
			let template = res.data;

			this.category.project_title_id = template.project_title_id;

			let bucketArray = [];
			this.category.directionList = template.directions;
			template.buckets.forEach((bucket) => {
				bucketArray.push(bucket.id);
			});
			this.category.selectedBucket = bucketArray;
			this.category['uploadedFile'] = template.document_files;
			this.category['uploadedFile'].forEach((file) => {
				file.name = file.filename;
			});

			this.category.directionList = template.directions;
			this.category.directionList?.forEach((direction) => {
				direction.textHtml = direction.text.replaceAll('\n', '<br>');
			});

			this.category['type'] = template.project_type_id;
			this.category['project_title_id'] = template.project_title_id;
			this.category['title'] = template.project_title_id;
			this.category['project_title_name'] = template.project_title_name;

			this.aExcludedTeamMembers = template.excluded_designers;
			this.aPayloadInviteDesigner = template.invited_designers;

			this.oProjectsData['project_question_answers'] =
				this.polyfilledStructuredClone(template.question_answers);

			this.getSuggestedTeamMembers(true);
			this.initQuestionDefs(
				false,
				() => this.fillQuestionAnswersForTemplates(),
				true
			);
			this.getTeamMembers(true);
			this._bucketService.selectedBucket.next(
				this.category['selectedBucket']
			);
		});
	}
	polyfilledStructuredClone<T>(obj: T): T {
		return JSON.parse(JSON.stringify(obj));
	}

	submitMarketplaceProject() {
		this.marketplaceCategory['projectTitle'] =
			this.marketplaceCategory['projectTitle'].trim();
		this._projectService.isSaveProject.next(true);
		setTimeout(() => {
			if (
				!this.marketplaceCategory['titleName'] &&
				this.isMarketplaceTab &&
				this.marketplaceCategory['projectTitle']
			) {
				this.isMarketplaceValidationShown = true;
				this.scrollToCategoryMessage.nativeElement.scrollIntoView({
					behavior: 'smooth',
				});
			} else {
				if (
					!this.marketplaceCategory['projectTitle'].length ||
					!this.marketplaceCategory['directionList']?.length
				) {
					this.scrollToInvalidControl();
					this.isMarketplaceValidationShown = true;
				} else {
					this.onMarketplaceQuestionsSubmit();
				}
			}
		}, 1000);
	}

	onMarketplaceQuestionsSubmit(oParams?) {
		if (
			(this.marketplaceCategory['projectTitle'] &&
				this.marketplaceCategory['directionList']?.length) ||
			oParams
		) {
			this.bDisableBtn = true;
			let project_title = '';
			const question_answers: Array<object> = [];
			const oDocumentApis: object = {};
			oDocumentApis['uploadedFile'] =
				this.marketplaceCategory['uploadedFile'];
			let aPayloadBucket = [];
			if (this.category['selectedBucket']) {
				aPayloadBucket = this.category['selectedBucket'];
			}
			let aPayloadInviteDesigner = [];

			if (this.marketplaceCategory['title']) {
				if (this.marketplaceQuestions) {
					if (this.marketplaceQuestions?.length > 0) {
						for (
							let k = 0;
							k < this.marketplaceQuestions.length;
							k++
						) {
							if (
								'custom_field' in this.marketplaceQuestions[k]
							) {
								if (
									this.marketplaceQuestions[k][
										'custom_field'
									] == 'title'
								) {
									project_title =
										this.marketplaceCategory[
											'questionForms'
										]['steps'].value['step' + (k + 1)][
											'title'
										];
									continue;
								} else if (
									this.marketplaceQuestions[k][
										'custom_field'
									] == 'bucket'
								) {
									aPayloadBucket =
										this.marketplaceCategory[
											'selectedBucket'
										];
									continue;
								} else if (
									this.marketplaceQuestions[k][
										'custom_field'
									] == 'invite_designer'
								) {
									aPayloadInviteDesigner =
										this.marketplaceCategory[
											'questionForms'
										]['steps'].value['step' + (k + 1)][
											'title'
										];
									continue;
								}
							}

							if (
								this.marketplaceQuestions[k][
									'project_question_type_id'
								] == this.oQuestionTypes['textarea']
							) {
								const answer =
									this.marketplaceCategory['questionForms'][
										'steps'
									].value['step' + (k + 1)]['title'];
								if (answer.match(/<img/)) {
									this._toasterService.pop(
										'error',
										'Oops, looks like an image got into the text box! Please remove it.'
									);
									this.bDisableBtn = false;
									return;
								}
							}

							const answers = {
								project_question_id:
									this.marketplaceQuestions[k][
										'project_question_id'
									],
								answer: this.marketplaceCategory[
									'questionForms'
								]['steps'].value['step' + (k + 1)]['title'],
								project_question_type_id:
									this.marketplaceQuestions[k][
										'project_question_type_id'
									],
								field_options:
									this.marketplaceQuestions[k][
										'field_options'
									],
								order: this.marketplaceQuestions[k]['order'],
							};
							if (
								answers?.project_question_type_id == 4 &&
								this.marketplaceCategory['questionForms'][
									'steps'
								].value['step' + (k + 1)]['title']?.length
							) {
								answers['answer'] = this.marketplaceCategory[
									'questionForms'
								]['steps'].value['step' + (k + 1)]['title'].map(
									(res) => {
										return res.name;
									}
								);
							}
							question_answers.push(answers);

							if (
								this.marketplaceQuestions[k][
									'project_question_type_id'
								] == 7
							) {
								if (
									this.marketplaceCategory['questionForms'][
										'steps'
									].value['step' + (k + 1)]['title']
								) {
									oDocumentApis[question_answers.length - 1] =
										this.marketplaceCategory[
											'questionForms'
										]['steps'].value['step' + (k + 1)][
											'title'
										];
								}
								if (
									this.oCopyBriefFiles['step' + (k + 1)] &&
									this.oCopyBriefFiles['step' + (k + 1)]
										.length > 0
								) {
									this.oPostCopyDocs[
										this.marketplaceQuestions[k][
											'project_question_id'
										]
									] = this.oCopyBriefFiles['step' + (k + 1)];
								}
							}
						}
					}
				}
			}
			if (this.marketplaceCategory['directionList']?.length) {
				this.marketplaceCategory['directionList'] =
					this.marketplaceCategory['directionList'].map((resData) => {
						const {
							isEdit,
							isUpdate,
							edit,
							updateDirection,
							textHtml,
							id,
							...cleanData
						} = resData;
						return cleanData;
					});
			}

			const restData = {
				created_by: this._localStorageService.getUserId(),
				project_title_id: this.projectTitleId,
				question_answers: question_answers,
				title: this.marketplaceCategory['projectTitle'],
				directions: this.marketplaceCategory['directionList'],
				invite_designers: this.aPayloadInviteDesigner,
				exclude_designers: this.aExcludedTeamMembers,
				query_string: this.oQueryString,
				copy_brief_docs: this.oPostCopyDocs,
				team_invite_status: this.teamInviteStatus,
				platinum_invited_designers: this.platinumInvitedDesigners,
			};

			if (!this.isEligibleActive) {
				restData['sort_order'] = this.sort_order;
			}

			this.apiFileUploadCount = 0;
			if (Object.keys(oDocumentApis).length > 0) {
				let k = 0;
				for (const oDocumentApi in oDocumentApis) {
					const oDocData = {};
					oDocData['name'] = 'File';
					oDocData['document_category_id'] = 2;
					oDocData['description'] = '';
					oDocData['description1'] = '';
					let n = 0;
					for (const oDoc of oDocumentApis[oDocumentApi]) {
						oDocData['file' + (n + 1)] = oDoc['file'];
						n++;
					}
					if (!environment.useAWSS3) {
						this.storeDocumentFile(oDocData).subscribe(
							(data) => {
								if (data) {
									this.bShowProgressBar = true;
									this.progressBarPercentage = data.progress;
									if (data['data']) {
										this.bShowProgressBar = false;
										if (data['data']['document_id']) {
											const document_id =
												data['data']['document_id'];
											restData['question_answers'][
												oDocumentApi
											]['answer'] = '';
											restData['question_answers'][
												oDocumentApi
											]['document_id'] = document_id;

											if (
												k + 1 ==
												Object.keys(oDocumentApis)
													.length
											) {
												this.postProject(
													restData,
													aPayloadBucket
												);
											}
											k++;
										}
									}
								}
							},
							() => {
								this.bDisableBtn = false;
								return false;
							}
						);
					} else {
						let documentId =
							oDocumentApi == 'uploadedFile' &&
							this.marketplaceCategory['document_id']
								? this.marketplaceCategory['document_id']
								: null;
						if (oDocumentApis[oDocumentApi].length != 0) {
							this.storeDocumentFileS3(
								oDocumentApis,
								oDocumentApi,
								restData,
								aPayloadBucket,
								documentId
							);
						} else {
							this.apiFileUploadCount++;
							if (
								this.apiFileUploadCount ==
									Object.keys(oDocumentApis).length &&
								!this.apiFileUploading
							) {
								this.postProject(restData, aPayloadBucket);
							}
						}
						k++;
					}
				}
			} else {
				this.postProject(restData, aPayloadBucket);
			}
		}
	}

	storeDocumentFile(oDoc) {
		return this._documentService.store(oDoc);
	}

	submitProjectInfo() {
		this.bDisableBtn = true;
		this.category['projectTitle'] = this.category['projectTitle'].trim();
		this._projectService.isSaveProject.next(true);

		setTimeout(() => {
			if (this.customSizeEnabled && !this.isMarketplaceTab) {
				let isAnySizeInvalid = false;
				this.category['customSizes'] = []; // Clear existing sizes

				this.customSizesArray.forEach((size) => {
					if (
						!size.customWidth ||
						!size.customHeight ||
						!size.customType
					) {
						isAnySizeInvalid = true;
					} else {
						this.category['customSizes'].push({
							customWidth: size.customWidth,
							customHeight: size.customHeight,
							customType: size.customType,
						});
					}
				});

				if (isAnySizeInvalid) {
					this.isValidationShown = true;
					this.bDisableBtn = false;
					this.customSizeMessage = true;
					return;
				}
			}

			if (
				!this.category['titleName'] &&
				!this.isMarketplaceTab &&
				this.category['projectTitle'].length &&
				!this.projectId
			) {
				this.isValidationShown = true;
				this.bDisableBtn = false;
				this.scrollToCategoryMessage.nativeElement.scrollIntoView({
					behavior: 'smooth',
				});
			} else {
				if (
					!this.category['projectTitle'].length ||
					!this.category['directionList']?.length ||
					(!this.category['questionForms']['steps']?.valid &&
						this.oDefs['questions'][this.category?.title]?.length)
				) {
					this.isValidationShown = true;
					this.bDisableBtn = false;
					this.scrollToInvalidControl();
				} else {
					this.onQuestionSubmit();
				}
			}
		}, 1000);
	}

	onQuestionSubmit(
		oParams?,
		templateDetails: { isTemplate: boolean; method: string } = {
			isTemplate: false,
			method: null,
		}
	) {
        this.bDisableBtn = true;
        let project_title = '';
        const question_answers: Array<object> = [];
        const oDocumentApis: object = {};
        oDocumentApis['uploadedFile'] = this.category['uploadedFile'];
        let aPayloadBucket = [];
        if (this.category['selectedBucket']) {
            aPayloadBucket = this.category['selectedBucket'];
        }
        let aPayloadInviteDesigner = [];

		if (this.category['title']) {
			if (this.oDefs['questions'][this.category['title']]) {
				if (
					this.oDefs['questions'][this.category['title']]?.length > 0
				) {
					for (
						let k = 0;
						k <
						this.oDefs['questions'][this.category['title']]?.length;
						k++
					) {
						if (
							'custom_field' in
							this.oDefs['questions'][this.category['title']][k]
						) {
							if (
								this.oDefs['questions'][this.category['title']][
									k
								]['custom_field'] == 'title'
							) {
								project_title =
									this.category['questionForms']['steps']
										.value['step' + (k + 1)]['title'];
								continue;
							} else if (
								this.oDefs['questions'][this.category['title']][
									k
								]['custom_field'] == 'bucket'
							) {
								aPayloadBucket =
									this.category['selectedBucket'];
								continue;
							} else if (
								this.oDefs['questions'][this.category['title']][
									k
								]['custom_field'] == 'invite_designer'
							) {
								aPayloadInviteDesigner =
									this.category['questionForms']['steps']
										.value['step' + (k + 1)]['title'];
								continue;
							}
						}

						if (
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == this.oQuestionTypes['textarea']
						) {
							const answer =
								this.category['questionForms']['steps'].value[
									'step' + (k + 1)
								]['title'];
							if (answer.match(/<img/)) {
								this._toasterService.pop(
									'error',
									'Oops, looks like an image got into the text box! Please remove it.'
								);
								this.bDisableBtn = false;
								return;
							}
						}

						let answers = {
							project_question_id:
								this.oDefs['questions'][this.category['title']][
									k
								]['project_question_id'],
							answer: this.category['questionForms']['steps']
								.value['step' + (k + 1)]['title'],
							project_question_type_id:
								this.oDefs['questions'][this.category['title']][
									k
								]['project_question_type_id'],
							field_options:
								this.oDefs['questions'][this.category['title']][
									k
								]['field_options'],
							order: this.oDefs['questions'][
								this.category['title']
							][k]['order'],
						};

						if (
							this.oDefs['questions'][this.category['title']][k][
								'title'
							] == 'Sizes' &&
							this.customSizeEnabled
						) {
							const customSizeOptions = this.customSizesArray.map(
								(size) => [
									{
										text: 'Height',
										key: 'height',
										value: size.customHeight || '',
									},
									{
										text: 'Width',
										key: 'width',
										value: size.customWidth || '',
									},
									{
										text: 'Dimension',
										key: 'dimension',
										value: size.customType || '',
									},
									{
										key: 'checked',
										value: size.selected ? true : false,
									},
								]
							);

							answers['answer_options'] = {
								custom_size: this.customSizeEnabled ? 1 : 0,
								options: customSizeOptions,
							};
						}

						if (
							answers?.project_question_type_id == 4 &&
							this.category['questionForms']['steps'].value[
								'step' + (k + 1)
							]['title']?.length
						) {
							answers['answer'] = [];
							this.category['questionForms']['steps'].value[
								'step' + (k + 1)
							]['title'].forEach((res) => {
								if (res?.value) {
									answers['answer'].push(res.name);
								}
							});
						}
						question_answers.push(answers);

						if (
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 7
						) {
							if (
								this.category['questionForms']['steps'].value[
									'step' + (k + 1)
								]['title']
							) {
								oDocumentApis[question_answers.length - 1] =
									this.category['questionForms'][
										'steps'
									].value['step' + (k + 1)]['title'];
							}
							if (
								this.oCopyBriefFiles['step' + (k + 1)] &&
								this.oCopyBriefFiles['step' + (k + 1)].length >
									0
							) {
								this.oPostCopyDocs[
									this.oDefs['questions'][
										this.category['title']
									][k]['project_question_id']
								] = this.oCopyBriefFiles['step' + (k + 1)];
							}
						}
					}
				}
			}
		}

		if (this.category['directionList']?.length) {
			this.category['directionList'] = this.category['directionList'].map(
				(resData) => {
					const {
						isEdit,
						isUpdate,
						edit,
						updateDirection,
						textHtml,
						id,
						...cleanData
					} = resData;
					return cleanData;
				}
			);
		}

		let restData;
		let templateRestData: TemplatePayload;

		if (!templateDetails.isTemplate) {
			restData = {
				created_by: this._localStorageService.getUserId(),
				project_title_id: this.category['title'],
				question_answers: question_answers,
				directions: this.category['directionList'],
				document_id: this.category['document_id'],
				title: this.category['projectTitle'],
				invite_designers: this.aPayloadInviteDesigner,
				exclude_designers: this.aExcludedTeamMembers,
				query_string: this.oQueryString,
				copy_brief_docs: this.oPostCopyDocs,
				team_invite_status: this.teamInviteStatus,
				platinum_invited_designers: this.platinumInvitedDesigners,
			};
			if (!this.isEligibleActive) {
				restData['sort_order'] = this.sort_order;
			}
			if (this.currentTemplateId) {
				restData['project_template_id'] = this.currentTemplateId;
			}
		} else {
			templateRestData = {
				created_by: +this._localStorageService.getUserId(),
				project_title_id: this.category['title'],
				question_answers: question_answers,
				directions: this.category['directionList'],
				document_id: this.category['document_id'],
				title: this.templateName.value,
				project_title: this.category['projectTitle'],
				invited_designers: this.aPayloadInviteDesigner,
				excluded_designers: this.aExcludedTeamMembers,
				buckets: aPayloadBucket.map((id) => ({ id: id })),
				assigned_users: [],
				sort_order: this.sort_order,
			};
			restData = templateRestData;
		}

		this.apiFileUploadCount = 0;
		if (Object.keys(oDocumentApis).length > 0) {
			let k = 0;
			for (const oDocumentApi in oDocumentApis) {
				const oDocData = {};
				oDocData['name'] = 'File';
				oDocData['document_category_id'] = 2;
				oDocData['description'] = '';
				oDocData['description1'] = '';
				let n = 0;
				for (const oDoc of oDocumentApis[oDocumentApi]) {
					oDocData['file' + (n + 1)] = oDoc['file'];
					n++;
				}
				if (!environment.useAWSS3) {
					this.storeDocumentFile(oDocData).subscribe(
						(data) => {
							if (data) {
								this.bShowProgressBar = true;
								this.progressBarPercentage = data.progress;
								if (data['data']) {
									this.bShowProgressBar = false;
									if (data['data']['document_id']) {
										const document_id =
											data['data']['document_id'];
										restData['question_answers'][
											oDocumentApi
										]['answer'] = '';
										restData['question_answers'][
											oDocumentApi
										]['document_id'] = document_id;

										if (
											k + 1 ==
											Object.keys(oDocumentApis).length
										) {
											if (
												templateDetails.isTemplate &&
												templateDetails.method ===
													'create'
											) {
												this.storeTemplate(restData);
											} else if (
												templateDetails.isTemplate &&
												templateDetails.method ===
													'update'
											) {
												this.updateTemplate(restData);
											} else {
												this.postProject(
													restData,
													aPayloadBucket
												);
											}
										}
										k++;
									}
								}
							}
						},
						() => {
							this.bDisableBtn = false;
							return false;
						}
					);
				} else {
					let documentId =
						oDocumentApi == 'uploadedFile' &&
						this.category['document_id']
							? this.category['document_id']
							: null;
					let section = oDocumentApi == 'uploadedFile' ? 1 : 2;
					if (oDocumentApis[oDocumentApi].length != 0) {
						this.storeDocumentFileS3(
							oDocumentApis,
							oDocumentApi,
							restData,
							aPayloadBucket,
							documentId,
							section,
							templateDetails
						);
					} else {
						this.apiFileUploadCount++;
						if (
							this.apiFileUploadCount ==
								Object.keys(oDocumentApis).length &&
							!this.apiFileUploading
						) {
							if (
								templateDetails.isTemplate &&
								templateDetails.method === 'create'
							) {
								this.storeTemplate(restData);
							} else if (
								templateDetails.isTemplate &&
								templateDetails.method === 'update'
							) {
								this.updateTemplate(restData);
							} else {
								this.postProject(restData, aPayloadBucket);
							}
						}
					}
					k++;
				}
			}
		} else {
		}
	}

	postProject(restData, aPayloadBucket?) {
		if(this.isOverlay) {
			restData['has_created_via_overlay'] = 1;
		}
		if (
			this.category['documentFileDetail']?.deletedFile?.length &&
			!this.isMarketplaceTab
		) {
			// this.deleteFile(
			// 	restData['document_id'],
			// 	this.category['documentFileDetail']['deletedFile']
			// );
			restData['exclude_document_file_ids'] =
				this.category['documentFileDetail']['deletedFile'];
		}

		if (
			this.marketplaceCategory['documentFileDetail']?.deletedFile
				?.length &&
			this.isMarketplaceTab
		) {
			this.deleteFile(
				restData['document_id'],
				this.marketplaceCategory['documentFileDetail']['deletedFile']
			);
		}
		let params = {};
		if (this.isProjectManagerUser && this.bCopyBrief) {
			params['user_id'] = this.oProject.created_by;
		}
		if (
			(this.isOperationalClientUser &&
				this.draftParams == this.draftParam) ||
			((this.selectedPackage || this.marketplaceCategory['type']) &&
				this.hasMarketplaceAccess &&
				this.isMarketplaceTab)
		) {
			params['project_status_id'] = 5;
		}
		if (this.action === 'update-brief') {
			delete params?.['project_status_id'];
		}
		if (
			this.hasMarketplaceAccess &&
			this.selectedPackage &&
			this.isMarketplaceTab
		) {
			restData = {
				...restData,
				concept_id: this.projectConcepts.marketplace,
				package_id: this.selectedPackage?.id,
			};
		}

		let projectStarted;
		if (
			this.openPaymentModel &&
			this.draftParams != this.draftParam &&
			this.isMarketplaceTab
		) {
			this.isPaymentModalOpen = true;
			return;
		}
		if (
			this.action == 'start-project' &&
			this.draftParams != this.draftParam
		) {
			this.isMarketplaceValidationShown = false;
			if (
				this.hasMarketplaceAccess &&
				(this.selectedPackage || this.marketplaceCategory['type']) &&
				this.isMarketplaceTab
			) {
				restData = {
					...restData,
					concept_id: this.projectConcepts.marketplace,
				};
				aPayloadBucket = this.marketplaceCategory['selectedBucket'];
			} else {
				restData['action'] = 'start-project';
			}
			projectStarted = this.storeProjectFromDraft(
				this.oQueryString['parent_id'],
				restData,
				params,
				aPayloadBucket
			);
		} else if (this.action == 'start-project'
			&& this.draftParams == this.projectStatus.draft) {
			restData['action'] = 'update-draft';
			projectStarted = this.storeProjectFromDraft(
				this.oQueryString['parent_id'],
				restData,
				params,
				aPayloadBucket
			);
		} else if (this.action === 'update-brief') {
			restData['action'] = 'update-brief';
			projectStarted = this.storeProjectFromDraft(
				this.oQueryString['parent_id'],
				restData,
				params,
				aPayloadBucket
			);
		} else {
			projectStarted = this.storeProject(
				restData,
				params,
				aPayloadBucket
			);
		}

		projectStarted.then((data) => {
			this.isSkeletonLoading = false;
			if (this.aPayloadInviteDesigner.length) {
				let oData = {
					ids: this.aPayloadInviteDesigner,
				};
			}
			if (this.action == 'redo-project') {
				this.retrieveUsers();
				this.startedProject = data;
			} else {
				this.currentProject = data;
				if (
					this.hasMarketplaceAccess &&
					!this.draftParams &&
					(this.selectedPackage ||
						this.marketplaceCategory['type']) &&
					this.isMarketplaceTab
				) {
					this.isSkeletonLoading = true;
					this.isPaymentModalOpen = true;
				} else {
					this.redirectProject(data['project_status_id'], data);
				}
			}
			this.bDisableBtn = false;
			this.isEdited.emit(false);
			this.closeOverlay.emit(true);
		});
	}

	storeDocumentFileS3(
		oDocumentApis,
		oDocumentApi,
		restData,
		aPayloadBucket,
		documentId?,
		section?,
		templateDetails?
	) {
		this.apiFileUploading = true;

		let oFiles = oDocumentApis[oDocumentApi];

		const transformObservables = oFiles.map((file) => {
			if (file.full_path) {
				return this._fileService
					.transformInputToFileStructure(file)
					.pipe(
						map((modifiedFile) => {
							return modifiedFile;
						})
					);
			} else {
				return of(file);
			}
		});

		forkJoin(transformObservables).subscribe((transformedFiles: any) => {
			oFiles = transformedFiles.map((file) => file.file);
			oFiles = this._s3DocumentService.generateFileHash(oFiles);


		let startTime = moment();
		this._analyticsService
			.storeDocumentTrackData(2, 17, 0, oFiles)
			.subscribe((res) => {
				this.oFileAnalyticsId[oDocumentApi] = res.data.request_id;
			});
		let oFileProgress = {};
		this.bShowProgressBar = true;
		let uploadFilePromises = this._s3DocumentService.uploadFiles(
			oFiles,
			2,
			function (evt) {
				if(this.bShowProgressBar && ((this.category['uploadedFile'].length && !this.isMarketplaceTab) || (this.marketplaceCategory['uploadedFile'].length && this.isMarketplaceTab))){
					this.scrollToProgressBar.nativeElement.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				}
				let percent = Math.round((evt.loaded * 100) / evt.total);
				oFileProgress[evt.key] = percent;
				let progressPercentage = 0;
				for (const key in oFileProgress) {
					progressPercentage =
						progressPercentage + oFileProgress[key];
				}
				this.bShowProgressBar = true;
				if (section == 1) {
					this.progressBarPercentage = Math.min(
						Math.floor(progressPercentage / oFiles.length),
						100
					);
				} else {
					this.progressBarPercentageSection2 = Math.min(
						Math.floor(progressPercentage / oFiles.length),
						100
					);
				}
				if (
					this.bShowProgressBar &&
					((this.category['uploadedFile'].length &&
						!this.isMarketplaceTab) ||
						(this.marketplaceCategory['uploadedFile'].length &&
							this.isMarketplaceTab))
				) {
					this.scrollToProgressBar.nativeElement.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				}
			}.bind(this)
		);

			Promise.all(uploadFilePromises).then(
				async (res) => {
					this.updateFileAnalytics(startTime, oDocumentApi, 2);

					let postFiles = [];
					for (let i = 0; i < oFiles.length; i++) {
						const file = oFiles[i];
						postFiles.push({
							filename: file.name,
							file_hash: file.file_hash,
							description: file?.description,
						});
					}
					let storeFilesData = {
						document_category_id: 2,
						files: postFiles,
					};

					if (
						this.bCopyBrief &&
						(this.category['copyBriefFile']?.length ||
							this.marketplaceCategory['copyBriefFile']?.length)
					) {
						let data = {};
						if (
							this.category?.documentFileDetail
								?.excludeDocumentFile?.length ||
							this.marketplaceCategory?.documentFileDetail
								?.excludeDocumentFile
						) {
							// data['exclude_document_file_ids'] = this.category
							// 	?.documentFileDetail?.excludeDocumentFile?.length
							// 	? this.category['documentFileDetail'][
							// 			'excludeDocumentFile'
							// 	  ]
							// 	: this.marketplaceCategory?.documentFileDetail
							// 			?.excludeDocumentFile;

							data['exclude_document_file_ids'] =
								this.category?.documentFileDetail
									?.deletedFile || [];
						} else if (
							this.category['copyBriefFile'].length ||
							this.marketplaceCategory['copyBriefFile'].length
						) {
							// data['exclude_document_file_ids'] = this.category
							// 	?.copyBriefFile?.length
							// 	? this.category?.copyBriefFile
							// 	: this.marketplaceCategory?.copyBriefFile;

							data['exclude_document_file_ids'] =
								this.category?.documentFileDetail
									?.deletedFile || [];
						}
						this._documentService
							.postCopyBriefFile(documentId, data)
							.subscribe((data) => {
								documentId = data?.data?.document_id;
								restData['document_id'] =
									data?.data?.document_id;
								this.postUploadedFile(
									documentId,
									storeFilesData,
									oDocumentApi,
									restData,
									oDocumentApis,
									aPayloadBucket,
									section,
									templateDetails
								);
							});
					} else {
						this.postUploadedFile(
							documentId,
							storeFilesData,
							oDocumentApi,
							restData,
							oDocumentApis,
							aPayloadBucket,
							section,
							templateDetails
						);
					}
				},
				(err) => {
					this.bDisableBtn = false;
					this.bShowProgressBar = false;
					this._toasterService.pop(
						'error',
						'There was an error uploading your files'
					);
					this.updateFileAnalytics(startTime, oDocumentApi, 3, err);
				}
			);
		});
	}

	deleteFile(documentId, documentIds) {
		this._documentService
			.deleteDocumentFiles(documentId, documentIds)
			.subscribe(() => {});
	}

	postUploadedFile(
		documentId: any,
		storeFilesData: any,
		oDocumentApi: string,
		restData: any,
		oDocumentApis: any,
		aPayloadBucket: any,
		section: number,
		templateDetails?
	) {
		if (
			(this.category['copyBriefFile'] &&
				this.category['copyBriefFile'].length > 0 &&
				!this.isMarketplaceTab &&
				documentId) ||
			(this.marketplaceCategory['copyBriefFile'] &&
				this.isMarketplaceTab &&
				documentId)
		) {
			this.marketplaceCategory['document_id'] = '';
			this.category['document_id'] = '';
			this._s3DocumentService
				.updateDocumentFiles(documentId, storeFilesData)
				.subscribe(() => {
					this.bShowProgressBar = false;
					this.apiFileUploadCount++;
					if (
						this.apiFileUploadCount ==
						Object.keys(oDocumentApis).length
					) {
						if (
							templateDetails.isTemplate &&
							templateDetails.method === 'create'
						) {
							this.storeTemplate(restData);
						} else if (
							templateDetails.isTemplate &&
							templateDetails.method === 'update'
						) {
							this.updateTemplate(restData);
						} else {
							this.postProject(restData, aPayloadBucket);
						}
					}
				});
		} else {
			this._s3DocumentService
				.storeDocumentFiles(storeFilesData)
				.subscribe((res) => {
					this.bShowProgressBar = false;
					const document_id = res.data[0];
					if (oDocumentApi == 'uploadedFile') {
						restData['document_id'] = document_id;
					} else {
						restData['question_answers'][oDocumentApi]['answer'] =
							'';
						restData['question_answers'][oDocumentApi][
							'document_id'
						] = document_id;
					}
					this.apiFileUploadCount++;
					if (
						this.apiFileUploadCount ==
						Object.keys(oDocumentApis).length
					) {
						if (
							templateDetails.isTemplate &&
							templateDetails.method === 'create'
						) {
							this.storeTemplate(restData);
						} else if (
							templateDetails.isTemplate &&
							templateDetails.method === 'update'
						) {
							this.updateTemplate(restData);
						} else {
							this.postProject(restData, aPayloadBucket);
						}
					}
				});
		}
	}

	storeProjectFromDraft(id, restData, params, aPayloadBucket?) {
		return new Promise((resolve, reject) => {
			this._projectService.startFromDraft(id, restData, params).subscribe(
				(data) => {
					let toastMessage;
					if (
						params['project_status_id'] !=
						this.projectStatus['draft']
					) {
						toastMessage =
							"Your project has been posted! Let's get things done...";
					} else {
						toastMessage =
							'Your project has been saved to drafts...';
					}
					this._toasterService.pop('success', toastMessage);
					data = data?.data ? data?.data : data;
					this.shareBuckets(data.project_id, aPayloadBucket);
					if (this.category['selectedSubUser'].length > 0) {
						this.saveAssignedUser(data.project_id);
					}
					if (this.teamInviteStatus) {
						this.assignInviteExcludeTeam(data.project_id);
					}
					resolve(data);
				},
				() => {
					this.bDisableBtn = false;
					reject();
				}
			);
		});
	}

	assignInviteExcludeTeam(project_id) {
		let teamRequest;
		if (this.teamInviteStatus == 1) {
			teamRequest = this._projectInviteDesignersService.inviteTeam(
				project_id,
				{}
			);
		} else if (this.teamInviteStatus == 2) {
			teamRequest = this._projectExcludeDesignersService.excludeTeam(
				project_id,
				{}
			);
		}
		if (teamRequest) {
			teamRequest.subscribe(
				() => {},
				() => {}
			);
		}
	}

	storeProject(restData, params, aPayloadBucket?) {
		if (this.action == 'redo-project') {
			params['action'] = 'redo-project';
		}
		return new Promise((resolve, reject) => {
			this._projectService.post(restData, params).subscribe(
				(data) => {
					let toastMessage;
					if (
						!(
							this.selectedPackage ||
							this.marketplaceCategory['type']
						)
					) {
						toastMessage =
							params['project_status_id'] !=
							this.projectStatus['draft']
								? "Your project has been posted! Let's get things done..."
								: 'Your project has been saved to drafts...';
					} else {
						toastMessage =
							'Your project has been saved to drafts...';
					}
					this._toasterService.pop('success', toastMessage);
					data = data.data;
					this.oQueryString['parent_id'] = data['project_id'];
					if (this.category['selectedSubUser'].length > 0) {
						this.saveAssignedUser(data.project_id);
					}
					if (this.category['selectedBucket']?.length) {
						this.shareBuckets(
							data.project_id,
							this.category['selectedBucket']
						);
					}
					if (this.teamInviteStatus) {
						this.assignInviteExcludeTeam(data.project_id);
					}
					this.bDisableBtn = false;
					resolve(data);
				},
				() => {
					this.bDisableBtn = false;
					reject();
				}
			);
		});
	}

	storeTemplate(restData: TemplatePayload) {
		this._projectService.postTemplate(restData).subscribe(
			(data) => {
				let toastMessage = 'Your template has been saved';
				this.isSavedProject = true;
				this.templateName.patchValue('');
				this.currentTemplateId = data.data[0].id;
				this.category.selected = data.data[0].id + 'tmp';
				this._toasterService.pop('success', toastMessage);
				this.bDisableBtn = false;
				this.retrieveTemplates();
				if (this.currentTemplateId) {
					this.getTemplateById(this.currentTemplateId);
				}
			},
			() => {
				this.bDisableBtn = false;
			}
		);
	}
	updateTemplate(restData: TemplatePayload) {
		this._projectService
			.updateTemplate(this.currentTemplateId, restData)
			.subscribe(
				(data) => {
					let toastMessage = 'Your template has been updated';
					this._toasterService.pop('success', toastMessage);
					this.bDisableBtn = false;
					this.retrieveTemplates();
					this.getTemplateById(this.currentTemplateId);
				},
				() => {
					this.bDisableBtn = false;
				}
			);
	}

	copyBriefFileDetail(event) {
		if (this.isMarketplaceTab) {
			this.marketplaceCategory['documentFileDetail'] = event;
		} else {
			this.category['documentFileDetail'] = event;
		}
	}

	shareBuckets(project_id, aData) {
		const data = {
			bucket_ids: aData != '' ? aData : [],
			is_post_project: 1,
		};
		if (project_id) {
			this._projectBucketService
				.storeSharedBuckets(project_id, data)
				.subscribe(() => {});
		}
	}

	retrieveUsers() {
		return new Promise<void>((resolve, reject) => {
			this._projectUserService
				.get(this.oProject.project_id)
				.subscribe((data) => {
					this.aProjectUsers = data.data;
					this.retrieveFeedbackQuestionsDefs();
					resolve();
				});
		});
	}

	subUserDetail(event: any) {
		this.category['selectedSubUser'] = event;
	
		this.category['selectedSubUser'] = this.category['selectedSubUser'].map((subUser) => {
			subUser['has_permission_enabled'] = subUser.has_permission ? 1 : 0;

			let userIdentifier = subUser.invited === true ? { user_invite_id: subUser.id } : { id: subUser.id };
	
			return {
				...userIdentifier,
				has_permission_enabled: subUser['has_permission_enabled'],
				permissions: subUser.permissions
			};
		});
	
		let data = {
			users: this.category['selectedSubUser'],
		};
	}	

	saveAssignedUser(id) {
		let data = {
			users: this.category['selectedSubUser'],
		};
	
		this._projectAssignedUserService.store(id, data).subscribe(() => {
			this.category['selectedSubUser'] = [];
		});
	}	

	retrieveFeedbackQuestionsDefs() {
		let data = {};
		this.getDefs(data).subscribe((data) => {
			this.aFeedbackQuestions = data.data['feedback_questions'];
			this.prepareFinalizeProject();
		});
	}

	getDefs(data?) {
		return this._projectFinalizeService.getDefs(
			this.oProject.project_id,
			data
		);
	}

	redirectProject(project_status_id, data) {
		this.isSkeletonLoading = false;
		this.category = {};
		this.marketplaceCategory = {};
		this.isSavedProject = true;
		if (
			project_status_id == this.projectStatus['assigned'] ||
			project_status_id == this.projectStatus['completed']
		) {
			this._router.navigate(['/projects/' + data.project_id]);
		} else if (
			project_status_id == this.projectStatus['pending'] ||
			this.isPaymentSucceeded
		) {
			this._router.navigate([
				'/projects/' +
					(data?.project_id
						? data.project_id
						: this.oProject?.project_id),
			]);
		} else if (project_status_id == this.projectStatus['draft']) {
			this._router.navigate(['/projects/drafts']);
		} else {
			this._router.navigate(['/projects']);
		}
	}

	updatedAiFileList(event: any) {
		if (this.isMarketplaceTab) {
			this.marketplaceCategory['uploadedFile'] = event;
		} else {
			this.category['uploadedFile'] = event;
		}
	}

	updateFileAnalytics(startTime, oDocumentApi, status, err?) {
		const duration = moment().diff(startTime, 'seconds');
		let i = 0;
		this.oFileAnalyticsInterval[oDocumentApi] = setInterval(() => {
			if (this.oFileAnalyticsId[oDocumentApi]) {
				this._analyticsService
					.updateDocumentsTrackData(
						this.oFileAnalyticsId[oDocumentApi],
						{
							time_duration: duration,
							status: status,
							failed_reason: err,
						}
					)
					.subscribe();
				clearInterval(this.oFileAnalyticsInterval[oDocumentApi]);
			}
			++i;
			if (i == this.MAX_ANALYTICS_ATTEMPT) {
				clearInterval(this.oFileAnalyticsInterval[oDocumentApi]);
			}
		}, this.FILE_ANALYTICS_INTERVAL);
	}

	prepareFinalizeProject() {
		let desingerRatings = this.prepareDesignerRatings();

		let finalizeArray = {
			feedback_stars: [],
			feedback_message: '',
			designer_rating: desingerRatings,
			project_id: this.oProject.project_id,
			action: 'redo-project',
			started_project_id: this.startedProject['project_id'],
		};

		this.storeFinalize(finalizeArray);
		this.removeFromTeam(finalizeArray);
	}

	onProjectProceedSuccessful() {
		this.isPaymentSucceeded = true;
		this.isSkeletonLoading = false;
		this.redirectProject(
			this.currentProject['project_status_id'],
			this.currentProject
		);
	}

	prepareDesignerRatings() {
		return this.aProjectUsers
			.filter((designer) => designer['user_type_name'] == 'Designer')
			.map((designer) => {
				return {
					id: designer['id'],
					name: designer['name'],
					rating: 0,
					feedback: '',
					removeFromTeam: true,
					removeFromTeamMainProject: false,
					reason: '',
					reasonMainProject: '',
					project_type_tags: [],
				};
			});
	}

	storeFinalize(oData) {
		this._projectFinalizeService
			.store(this.oProject.project_id, oData)
			.pipe(takeUntil(this.destroyed$))
			.subscribe(
				(data) => {
					data['form'] = 'finalize';
				},
				() => {}
			);
	}

	removeFromTeam(oData) {
		if (oData['designer_rating']) {
			for (const oDesigner of oData['designer_rating']) {
				if (oDesigner['removeFromTeam'] == true) {
					const data = {
						project_id: this.oProject['project_id'],
						trigger_id: 5,
						comments: '',
						reason: oDesigner['reason'],
						project_title_id: this.oProject['project_title_id'],
					};

					this.destroyRemoveFromTeam(oDesigner, data);
				}
			}
		}
	}

	saveAiInfo(event) {
		if (this.isMarketplaceTab) {
			this.category['aiInfo'] = event;
		} else {
			this.marketplaceCategory['aiInfo'] = event;
		}
	}

	destroyRemoveFromTeam(oDesigner, data) {
		this._userClientService
			.destroyCreative(
				this.oProject['operational_id'],
				oDesigner['id'],
				data
			)
			.pipe(
				takeUntil(this.destroyed$),
				catchError((err) => {
					return throwError(err);
				})
			)
			.subscribe((data) => {
				this.redirectProject(data['project_status_id'], data);
			});
	}

	skeletonInfo() {
		if (this.isSkeletonShown && this.isSkeletonLoading) {
			setTimeout(() => {
				let elementByClassName: any = document.getElementsByClassName(
					'create-project-wrapper'
				);
				elementByClassName[0].click();
				this.isSkeletonShown = false;
				let element: any =
					document.getElementsByClassName('search-category');
				element[0]?.focus();
			});
		}
	}

	getAssignedUser(id) {
		this._projectAssignedUserService.index(id).subscribe((data) => {
			this.category['assignedUser'] = data.data.map(
				(res) => res?.assigned_user_id
			);
			this.assignedUserDataLoaded = true;
		});
	}

	onClosePaymentModel() {
		if (this.currentProject?.project_id || this.currentProject?.message) {
			this.openPaymentModel = true;
			this.isPaymentModalOpen = false;
			this.action = 'start-project';
		}
	}

	canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
		if (
			(this.category?.questionForms?.steps?.invalid ||
				this.category?.title ||
				this.category?.projectTitle ||
				this.marketplaceCategory?.questionForms?.steps?.invalid ||
				this.marketplaceCategory?.selectedTitleId ||
				this.marketplaceCategory?.type ||
				this.marketplaceCategory?.projectTitle) &&
			!this.isSavedProject
		) {
			return window.confirm(
				'You have unsaved changes. Do you want to leave this page?'
			);
		}
		return true;
	}

	scrollToInvalidControl() {
		if (
			!this.category['projectTitle'] ||
			!this.category['directionList']?.length
		) {
			this.scrollToInvalidField.nativeElement.scrollIntoView({
				behavior: 'smooth',
			});
		} else {
			this.scrollToInvalidQueField.nativeElement.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}

	selectType(event) {
		this.category['customType'] = event;
	}
	// For future use
	clearAllFields() {
		this.category['projectTitle'] = '';
		this.category['directionList'] = [];
		this.category['selectedBucket'] = [];
		this._bucketService.selectedBucket.next(
			this.category['selectedBucket']
		);
		this.category['uploadedFile'] = [];
		this.getTeamMembers(true);
		this.aExcludedTeamMembers = [];
		this.aPayloadInviteDesigner = [];
		this.initQuestionDefs();
		this.customSizesArray = [];
		this.category['customSizesArray'] = [];
		this.category['titleName'] = '';
		this.category['project_title_id'] = null;
		this.category['project_title_name'] = '';
		this.category['title'] = null;
		this.category['type'] = null;
		this.category['selected'] = '';
	}
	handleAccordionState(isOpen: boolean): void {
		this.isCategoriesFilterOpen = isOpen;
	}
	checkIfTemplatesPresent() {
		if (!this.isTemplatesPresentInCategory) {
			this.filterCategoriesForm.get('templates').disable();
		} else {
			this.filterCategoriesForm.get('templates').enable();
		}
	}
	createSubscriptionFilterChanges() {
		let subscription = this.filterCategoriesForm.valueChanges.subscribe((changes) => {
			// Determine which control changed
			for (const key in changes) {
				if (this.previousCategoryFilters[key] !== undefined && (changes[key] !== this.previousCategoryFilters[key])) {
					this.filterByCategories(key);
					break;
				}
			}
		});
		return subscription;
	}

	toggleCheckbox(controlName: string) {
		const control = this.filterCategoriesForm.get(controlName);
		if (control && !control.disabled) {
			control.setValue(!control.value, {emitEvent: false});
		}
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
